import { isEqual } from "lodash";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import { ProjectPayloadGet } from "domain/entities/project";
import { TenantPayloadGet } from "domain/entities/tenant";
import { UserPermission } from "domain/entities/user";
import { UserProfilePayloadGet } from "domain/entities/userProfile";

import { THEMES } from "../constants";

type UserPreferencesState = {
  tenantId: string;
  tenant?: TenantPayloadGet;
  project?: ProjectPayloadGet;
  user: UserProfilePayloadGet | undefined;
  permissions: Array<UserPermission>;
  language: string;
  theme: string;
  isFirstLogin: boolean;
  setTenantId: (tenantId: string) => void;
  setTenant: (tenant: TenantPayloadGet) => void;
  setProject: (project?: ProjectPayloadGet) => void;
  setUser: (user: UserProfilePayloadGet) => void;
  setPermissions: (permissions: Array<UserPermission>) => void;
  setLanguage: (language: string) => void;
  setTheme: (theme: string) => void;
  setIsFirstLogin: (isFirstLogin: boolean) => void;
};

const useUserPreferencesStore = create(
  persist<UserPreferencesState>(
    (set, get) => ({
      language: "",
      tenantId: "",
      theme: THEMES.DEFAULT,
      user: undefined,
      permissions: [],
      isFirstLogin: true,
      setTenantId: (_tenantId) => {
        set(() => {
          return { tenantId: _tenantId };
        });
      },
      setTenant: (_tenant) => {
        if (!isEqual(_tenant, get().tenant))
          set(() => {
            return {
              tenantId: _tenant.identifier.id,
              tenant: _tenant,
            };
          });
      },
      setProject: (_project) => {
        if (!isEqual(_project, get().project))
          set(() => {
            return {
              project: _project,
            };
          });
      },
      setPermissions: (_permissions) => {
        set(() => ({ permissions: _permissions }));
      },
      setUser: (_user) => {
        if (!isEqual(_user, get().user))
          set(() => {
            return {
              user: _user,
            };
          });
      },
      setLanguage: (_language) => {
        set(() => {
          return {
            language: _language,
          };
        });
      },
      setTheme: (_theme) => {
        set(() => {
          return {
            theme: _theme,
          };
        });
      },
      setIsFirstLogin: (_isFirstLogin) => {
        set(() => {
          return {
            isFirstLogin: _isFirstLogin,
          };
        });
      },
    }),
    {
      name: "preference-storage",
    },
  ),
);

export default useUserPreferencesStore;
