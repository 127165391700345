const FileUtils = {
  /**
   * @description Simulate download programmatically, by creating <a> tag with download attribute.
   * @param fileName File name
   * @param file File as URL path `string` or `Blob`
   * @return void
   */
  download(fileName: string, file: Blob | string) {
    const a = document.createElement("a");
    const url = file instanceof Blob ? URL.createObjectURL(file) : file;

    a.href = url;
    a.download = fileName;
    a.target = "_blank";
    a.click();

    if (file instanceof Blob) window.URL.revokeObjectURL(url);
  },

  downloadStringContent(
    fileContent: string,
    contentType: string,
    fileName: string,
  ) {
    this.download(fileName, new Blob([fileContent], { type: contentType }));
  },

  fileToByteArray(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        let fileByteArray: Uint8Array;
        reader.readAsArrayBuffer(file);
        reader.onloadend = (event) => {
          if (event.target?.readyState === FileReader.DONE) {
            const arrayBuffer = event.target.result as ArrayBufferLike;
            fileByteArray = new Uint8Array(arrayBuffer);
          }
          resolve(fileByteArray);
        };
      } catch {
        reject(new Error());
      }
    });
  },

  fileToFormData(file: File): FormData {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return formData;
  },

  fileToBinaryString(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = (event) => {
          if (event.target?.readyState === FileReader.DONE) {
            resolve(event.target.result);
          }
        };
      } catch {
        reject(new Error());
      }
    });
  },

  fileToDataUrl(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () =>
        reject(
          new Error(
            reader.error?.toString() ??
              "An error occurred while reading the file.",
          ),
        );
      reader.readAsDataURL(file);
    });
  },
};

export default FileUtils;
