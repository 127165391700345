import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import * as en from "./lang/en.json";
import * as hr from "./lang/hr.json";
import * as sr from "./lang/sr-Cyrl.json";

export const defaultNS = "translation";

export const resources = {
  en,
  hr,
  sr,
};

export const setupI18n = (language: string) => {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      defaultNS,
      fallbackLng: "en",
      lng: language,
      interpolation: {
        escapeValue: false,
      },
    });
};
