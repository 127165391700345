import { FC, ReactElement, ReactNode, useCallback } from "react";

import { useTranslation } from "react-i18next";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { default as MinorIcon } from "@mui/icons-material/KeyboardArrowUp";
import { default as MajorIcon } from "@mui/icons-material/KeyboardDoubleArrowUp";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  ListItem,
  ListItemButton,
  SvgIcon,
  Typography,
  Tooltip,
  IconButton,
  Link,
  Avatar as MuiAvatar,
  ListItemAvatar,
} from "@mui/material";

import styled from "@emotion/styled";

import { ColoredAvatarProps } from "components/notifications/types";
import ResourceGuard from "components/resourceGuard/ResourceGuard";

import { alarmEntityLocations } from "pages/alarms/constants";
import { AcknowledgeAlarmIcon } from "pages/alarms/img";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { alarmApi } from "fetch/alarm";
import { useApiMutation } from "fetch/queryUtils";

import { SEEN_ALARM_STATUSES } from "utils/constants";

import { AlarmSeverity, AlarmStatusType, EntityId } from "globalTypes";

import { AlarmProps } from "./types";

export const alarmSeverityIcon = (level: AlarmSeverity): ReactNode => {
  switch (level) {
    case AlarmSeverity.CRITICAL:
      return <ErrorOutlineIcon />;
    case AlarmSeverity.MAJOR:
      return <MajorIcon />;
    case AlarmSeverity.MINOR:
      return <MinorIcon />;
    case AlarmSeverity.WARNING:
      return <WarningAmberIcon />;
    default:
      return <QuestionMarkIcon />;
  }
};

const alarmStatusIcon = (status: AlarmStatusType): ReactElement => {
  switch (status) {
    case AlarmStatusType.ACTIVE_ACKNOWLEDGED:
      return <NotificationsActiveOutlinedIcon color="success" />;
    case AlarmStatusType.ACTIVE_UNACKNOWLEDGED:
      return <NotificationsActiveOutlinedIcon />;
    case AlarmStatusType.CLEARED_ACKNOWLEDGED:
      return <NotificationsOffOutlinedIcon color="success" />;
    case AlarmStatusType.CLEARED_UNACKNOWLEDGED:
      return <NotificationsOffOutlinedIcon color="error" />;
  }
};

export const alarmSeverityColours: Record<AlarmSeverity, string> = {
  CRITICAL: "error",
  MAJOR: "error",
  MINOR: "warning",
  WARNING: "warning",
  UNKNOWN: "info",
};

const AlarmCard: FC<Readonly<AlarmProps>> = ({
  alarm,
  expandMessage,
  refetch,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "component.alarm.expanded",
  });
  const isSeen = SEEN_ALARM_STATUSES.includes(alarm.status);
  const storage = useUserPreferencesStore();

  const mutationClear = useApiMutation(
    alarmApi.clearAlarm,
    t("request.cleared"),
    {
      onSuccess: () => refetch && refetch(),
    },
  );

  const mutationAcknowledge = useApiMutation(
    alarmApi.acknowledgeAlarm,
    t("request.acknowledged"),
    {
      onSuccess: () => refetch && refetch(),
    },
  );

  const openEntity = useCallback(
    (entity: EntityId) => {
      if (Object.keys(alarmEntityLocations).includes(entity.type)) {
        const regex = /^(.*?\/)(tenant|project)\//;
        const match = regex.exec(location.pathname);
        const basePath = match ? match[1] : "/";

        const isTenantPath = location.pathname.includes("/tenant/");

        let contextPath;
        if (isTenantPath && storage.project) {
          const projectUUID = storage.project.identifier.id;
          contextPath = `project/${projectUUID}`;
        } else {
          const regex = /\/project\/([^/]+)/;
          const match = regex.exec(location.pathname);
          const currentProjectUUID = match ? match[1] : undefined;
          contextPath = `project/${currentProjectUUID}`;
        }

        const newPath = `${basePath}${contextPath}/${alarmEntityLocations[entity.type]}/${entity.id}`;

        return newPath;
      }
    },
    [storage.project],
  );

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <ListItem
        onClick={expandMessage}
        disableGutters
        disablePadding
        sx={{
          flexDirection: "column",
          ".Mui-selected": {
            backgroundColor: (theme) => theme.palette.background.default,
          },
          ".MuiButtonBase-root": {
            width: "100%",
          },
        }}
      >
        <ListItemButton selected={isSeen}>
          <ListItemAvatar>
            <Tooltip title={alarm.severity}>
              <Avatar
                status={alarmSeverityColours[alarm.severity]}
                seen={isSeen.toString()}
              >
                <SvgIcon fontSize="small">
                  {alarmSeverityIcon(alarm.severity)}
                </SvgIcon>
              </Avatar>
            </Tooltip>
          </ListItemAvatar>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle2" color={"textPrimary"} noWrap>
                    {alarm.trigger.type}
                    {": "}
                    <Link href={openEntity(alarm.trigger)} variant="inherit">
                      {alarm.trigger.id}
                    </Link>
                  </Typography>
                  <Tooltip title={alarm.status}>
                    {alarmStatusIcon(alarm.status)}
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("type") + ": " + alarm.type}
                  </Typography>

                  <Typography variant="body2" color={"textSecondary"}>
                    {t("started") + ": " + alarm.events.started}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      maxWidth: 23,
                      justifyContent: "space-between",
                    }}
                  >
                    <ResourceGuard component-id="alarmsPage.alarmsTable.acknowledgeAlarm">
                      <Tooltip arrow title={t("buttons.acknowledge")}>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            mutationAcknowledge.mutate(alarm.identifier.id);
                          }}
                          disabled={
                            alarm.status ===
                              AlarmStatusType.ACTIVE_ACKNOWLEDGED ||
                            alarm.status ===
                              AlarmStatusType.CLEARED_ACKNOWLEDGED
                          }
                        >
                          <SvgIcon>
                            <AcknowledgeAlarmIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </ResourceGuard>
                    <ResourceGuard component-id="alarmsPage.alarmsTable.clearAlarm">
                      <Tooltip arrow title={t("buttons.clear")}>
                        <IconButton
                          onClick={() => {
                            mutationClear.mutate(alarm.identifier.id);
                          }}
                          color="secondary"
                          disabled={
                            alarm.status ===
                              AlarmStatusType.CLEARED_UNACKNOWLEDGED ||
                            alarm.status ===
                              AlarmStatusType.CLEARED_ACKNOWLEDGED
                          }
                        >
                          <NotificationsOffOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </ResourceGuard>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default AlarmCard;

const Avatar = styled(MuiAvatar)<ColoredAvatarProps>(
  ({ theme, status, seen }) => ({
    backgroundColor: theme.palette[status].main,
    opacity: seen === "true" ? 0.5 : 1,
  }),
);
