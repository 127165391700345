import React, { useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button as MuiButton, Typography } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import styled from "@emotion/styled";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page500() {
  const { t } = useTranslation("translation", {
    keyPrefix: "page.error.500",
  });

  useEffect(() => {
    localStorage.removeItem("authInProcess");
  }, []);

  return (
    <Wrapper>
      <Helmet title="500 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        500
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {t("title")}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {t("description")}
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="secondary"
        mt={2}
      >
        {t("returnButton")}
      </Button>
    </Wrapper>
  );
}

export default Page500;
