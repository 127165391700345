import {
  DeviceImportTemplateResponseSearch,
  DeviceImportTemplateResponseGet,
  DeviceImportTemplatePayloadPost,
  DeviceImportTemplateType,
  DeviceImportTemplatePayloadPut,
} from "domain/entities/deviceImportTemplate";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export const deviceImportTemplateApi = {
  createDeviceImportTemplate: (body: DeviceImportTemplatePayloadPost) =>
    axiosClient.post<DeviceImportTemplateResponseGet>(
      "/hive/device/import/template",
      body,
    ),
  updateDeviceImportTemplateById: (
    id: string,
    body: DeviceImportTemplatePayloadPut,
  ) =>
    axiosClient.put<DeviceImportTemplateResponseGet>(
      `/hive/device/import/template/${id}`,
      body,
    ),
  deleteDeviceImportTemplateById: (id: string) =>
    axiosClient.delete<DeviceImportTemplateResponseGet>(
      `/hive/device/import/template/${id}`,
    ),
};

export interface DeviceImportTemplateSearchQueryParams {
  projectId: string;
  name?: string;
  type?: DeviceImportTemplateType;
}

export const useDeviceImportTemplateSearch = createSearchApiQuery<
  DeviceImportTemplateResponseSearch,
  undefined,
  DeviceImportTemplateSearchQueryParams
>("/hive/device/import/template");

export const useDeviceImportTemplateById = createApiQuery<
  DeviceImportTemplateResponseGet,
  { deviceImportTemplateId: string }
>("/hive/device/import/template/{deviceImportTemplateId}");
