import { SystemPayload, SystemResponse } from "domain/entities/system";

import axiosClient from "./axios";
import { createApiQuery } from "./queryUtils";

export const systemApi = {
  updateSystem: (data: SystemPayload) =>
    axiosClient.put<SystemResponse>("/hive/system", data),
};

export const useSystem = createApiQuery<SystemResponse>("/hive/system");
