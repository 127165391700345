import { EntityId, ResponseWrapper } from "globalTypes";

export enum UsageTypes {
  MESSAGE = "MESSAGE",
  DATA_POINT = "DATA_POINT",
  STORAGE = "STORAGE",
  WORKFLOW = "WORKFLOW",
  ALARM = "ALARM",
}

export enum UsageProtocols {
  MQTT_CLIENT = "MQTT_CLIENT",
  MQTT_CLIENT_PUBLISH = "MQTT_CLIENT_PUBLISH",
  MQTT_SERVER = "MQTT_SERVER",
  HTTP_INBOUND = "HTTP_INBOUND",
  HTTP_OUTBOUND = "HTTP_OUTBOUND",
  GRPC_CLIENT = "GRPC_CLIENT",
  CHIRPSTACK = "CHIRPSTACK",
}

export enum UsageGroupTypes {
  PROJECT = "PROJECT",
  TYPE = "TYPE",
  PROTOCOL = "PROTOCOL",
  TIMESTAMP = "TIMESTAMP",
  NONE = "NONE",
}

export type UsageType = {
  type: UsageTypes;
  value: number;
  tenantId: EntityId;
  projectId: EntityId;
  timestamp: string;
  protocol?: UsageProtocols;
};

export interface UsageResponse {
  groupBy: UsageGroupTypes;
  key: UsageTypes;
  usage: Array<UsageType>;
}

export interface UsageResponseGet extends ResponseWrapper {
  payload: Array<UsageResponse>;
}
