import React from "react";

import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";

import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import {
  IconButton as MuiIconButton,
  Tooltip,
  Box,
  Alert,
  Link,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import styled from "@emotion/styled";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { useTenantSearch } from "fetch/tenant";

import { TenantPayloadGet } from "domain/entities/tenant";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarTenantSelect() {
  const storage = useUserPreferencesStore();
  const { t } = useTranslation();

  const handleSwitchTenantClick = (tenant: TenantPayloadGet) => {
    storage.setTenant(tenant);
    window.location.replace("/");
    closeMenu();
  };

  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const {
    data: tenantSearchData,
    isError,
    isLoading,
    refetch: refetchTenants,
  } = useTenantSearch(
    {},
    {
      queryParams: {},
    },
    {
      enabled: !!anchorMenu,
    },
  );

  const toggleMenu = (event: React.SyntheticEvent) => {
    refetchTenants();
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("navbar.tenantSelector.tooltip")}>
        <IconButton
          aria-owns={"menu-appbar"}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <RefreshCw />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {tenantSearchData?.payload.map((tenant) => (
          <MenuItem
            key={tenant.identifier.id}
            onClick={() => handleSwitchTenantClick(tenant)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              {tenant.image ? (
                <img
                  alt=""
                  height={25}
                  width={25}
                  src={tenant.image}
                  loading="lazy"
                  style={{ borderRadius: "10%" }}
                />
              ) : (
                <Box
                  width={25}
                  height={25}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ImageNotSupportedOutlinedIcon
                    sx={{ width: "50%", height: "50%", opacity: 0.3 }}
                  />
                </Box>
              )}
              <Box display="flex" flexDirection="column" gap={1}>
                <Box sx={{ fontWeight: 500 }}>{tenant.name}</Box>
              </Box>
            </Box>
          </MenuItem>
        ))}
        {isError && !isLoading ? (
          <Alert severity="error">
            {"Error fetching tenants"}

            <Link component="button" onClick={() => refetchTenants()}>
              {"Retry"}
            </Link>
          </Alert>
        ) : null}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarTenantSelect;
