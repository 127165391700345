import { ProjectOverviewResponse } from "domain/entities/overview";
import {
  ProjectPayloadPost,
  ProjectResponse,
  ProjectResponseSearch,
} from "domain/entities/project";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export const projectApi = {
  createProject: (data: ProjectPayloadPost) =>
    axiosClient.post<ProjectResponse>("/hive/project", data),
  updateProjectById: (projectId: string, data: ProjectPayloadPost) =>
    axiosClient.put<ProjectResponse>(`/hive/project/${projectId}`, data),
  deleteProjectById: (projectId: string) =>
    axiosClient.delete<ProjectResponse>(`/hive/project/${projectId}`),
  getProjectById: (projectId: string) =>
    axiosClient.get<ProjectResponse>(`/hive/project/${projectId}`),
  importProject: (requestBody: ProjectResponse) => {
    return axiosClient.post<ProjectImportResponse>("/hive/project/import", {
      project: requestBody,
    });
  },
  exportProject: (requestBody: ExportProjectsRequestBody) =>
    axiosClient.post<ProjectResponseSearch>(
      "/hive/project/export",
      requestBody,
    ),
};

export interface ExportProjectsRequestBody {
  id: string | null;
  specifications: string[];
  integrations: string[];
  devices: string[];
  dashboards: string[];
  workflows: string[];
}

export interface ProjectSearchQueryParams {
  name?: string;
  entitiesPerPage?: number;
  page?: number;
}

export interface ProjectImportResponse {
  responseCode: number;
  responseDetail: string;
  payload: object;
  successful: boolean;
  responseCodeObject: {
    name: string;
    code: number;
    category: string;
    description: string;
  };
}

export const useProjectSearch = createSearchApiQuery<
  ProjectResponseSearch,
  undefined,
  ProjectSearchQueryParams
>("/hive/project");

export const useProjectById = createApiQuery<
  ProjectResponse,
  { projectId: string }
>("/hive/project/{projectId}");

export const useProjectOverviewById = createApiQuery<
  ProjectOverviewResponse,
  { projectId: string }
>("/hive/project/overview/{projectId}");
