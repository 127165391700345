import {
  DeviceSpecificationPayloadPost,
  DeviceSpecificationResponse,
  DeviceSpecificationResponseSearch,
  DeviceSpecificationPayloadPut,
} from "domain/entities/deviceSpecification";

import {
  ConfigurationType,
  ImportResponse,
  ExportRequestBody,
} from "globalTypes";

import axiosClient from "./axios";
import {
  SearchParams,
  createApiQuery,
  createApiQueryInfinite,
  createSearchApiQuery,
  useApiMutation,
} from "./queryUtils";

export const deviceSpecificationApi = {
  getDeviceSpecification: (specificationId: string) =>
    axiosClient.get<DeviceSpecificationResponse>(
      `/hive/device/specification/${specificationId}`,
    ),
  createDeviceSpecification: (data: DeviceSpecificationPayloadPost) =>
    axiosClient.post<DeviceSpecificationResponse>(
      "/hive/device/specification",
      data,
    ),
  updateDeviceSpecificationById: (
    specificationId: string,
    data: DeviceSpecificationPayloadPut,
  ) =>
    axiosClient.put<DeviceSpecificationResponse>(
      `/hive/device/specification/${specificationId}`,
      data,
    ),
  deleteDeviceSpecificationById: (specificationId: string) =>
    axiosClient.delete<DeviceSpecificationResponse>(
      `/hive/device/specification/${specificationId}`,
    ),
  importDeviceSpecifications: (requestBody: DeviceSpecificationResponse) =>
    axiosClient.post<ImportResponse>("/hive/device/specification/import", {
      entities: requestBody,
    }),
  exportDeviceSpecifications: (requestBody: ExportRequestBody) =>
    axiosClient.post<DeviceSpecificationResponseSearch>(
      "/hive/device/specification/export",
      requestBody,
    ),
};

export interface DeviceSpecificationSearchQueryParams {
  projectId?: string;
  name?: string;
  type?: ConfigurationType;
}

export const useDeviceSpecificationSearch = createSearchApiQuery<
  DeviceSpecificationResponseSearch,
  undefined,
  DeviceSpecificationSearchQueryParams
>("/hive/device/specification");

export const useDeviceSpecificationInfinite = createApiQueryInfinite<
  DeviceSpecificationResponseSearch,
  undefined,
  DeviceSpecificationSearchQueryParams & SearchParams,
  number
>("/hive/device/specification", {
  getNextPageParam: (lastPage) => {
    if (lastPage.page < lastPage.numberOfPages) {
      return lastPage.page + 1;
    }
  },
});

export const useDeviceSpecificationById = createApiQuery<
  DeviceSpecificationResponse,
  { specificationId: string }
>("/hive/device/specification/{specificationId}", 60 * 1000);

export const useDeviceSpecificationMutationUpdate = (
  id: string | undefined,
) => {
  return useApiMutation(
    (payload: DeviceSpecificationPayloadPut) => {
      return deviceSpecificationApi.updateDeviceSpecificationById(
        id ?? "",
        payload,
      );
    },
    (response) => {
      return response.data?.responseDetail ?? "OK";
    },
    {
      meta: {
        dataKeys: [
          useDeviceSpecificationById.getPrimaryKey(),
          { pathParams: { specificationId: id } },
        ],
      },
    },
  );
};
