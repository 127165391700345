import { PropsWithChildren } from "react";

import { Box, Tooltip, Typography } from "@mui/material";

import { resourcePermissionCheck } from "./resourceGuardUtil";
import { ResourceGuardType } from "./types";

const DevelopmentTooltip = (props: PropsWithChildren) => {
  if (process.env.NODE_ENV === "production") {
    return null;
  }

  return (
    <Tooltip
      children={
        <Box sx={{ borderRadius: "4px", backgroundColor: "#ec7777" }}>
          {props.children}
        </Box>
      }
      title={
        <Typography>
          MISSING `component-id`: This element will not be visible in a
          production environment (deployed application)!
        </Typography>
      }
    />
  );
};

const ResourceGuard = (props: PropsWithChildren<ResourceGuardType>) => {
  if (!props["component-id"])
    return <DevelopmentTooltip children={props.children} />;

  const permission = resourcePermissionCheck(props["component-id"]);

  if (!permission?.visible) return null;

  return props.children;
};

export default ResourceGuard;
