import {
  Bell,
  Book,
  Cloud,
  Codesandbox,
  Database,
  DownloadCloud,
  Home,
  Layout,
  Settings,
  Sliders,
  Tablet,
  Users,
  FileText,
} from "react-feather";

import { PermIdentity, Schema } from "@mui/icons-material";

import { TFunction } from "i18next";

import { resourcePermissionCheckVisibility } from "components/resourceGuard/resourceGuardUtil";

import { ProjectPayloadGet } from "domain/entities/project";
import { TenantPayloadGet } from "domain/entities/tenant";

import { EntityType } from "globalTypes";
import { SidebarItemsType } from "types/sidebar";

type SystemSectionProps = {
  t: TFunction;
};

type TenantSectionProps = {
  t: TFunction;
  tenant?: TenantPayloadGet;
};

type ProjectSectionProps = {
  t: TFunction;
  project?: ProjectPayloadGet;
};

type NavItemsProps = {
  tenant?: TenantPayloadGet;
  project?: ProjectPayloadGet;
  t: TFunction;
};

export type NavItem = {
  title: string;
  pages: SidebarItemsType[];
  type?: string;
};

const SystemSection = ({ t }: SystemSectionProps) => {
  return [
    {
      href: `/system/settings`,
      icon: Settings,
      title: t("sidebar.settings"),
      requiredPermissions: {
        [EntityType.SYSTEM]: ["UPDATE"],
      },
      "component-id": "settingsPage",
    },
    {
      href: `/system/tenants`,
      icon: Users,
      title: t("sidebar.tenants"),
      "component-id": "tenantsPage",
    },
  ] as SidebarItemsType[];
};

const TenantSection = ({ tenant, t }: TenantSectionProps) => {
  return [
    {
      href: `/tenant/${tenant?.identifier.id}/overview`,
      icon: Home,
      title: t("sidebar.overview"),
      requiredPermissions: {
        [EntityType.TENANT]: ["READ"],
      },
      "component-id": "tenantOverviewPage",
    },
    {
      href: `/tenant/${tenant?.identifier.id}/project`,
      icon: Book,
      title: t("sidebar.projects"),
      requiredPermissions: {
        [EntityType.PROJECT]: ["READ"],
      },
      "component-id": "projectsPage",
    },
    {
      href: `/tenant/${tenant?.identifier.id}/authorities`,
      icon: PermIdentity,
      title: t("sidebar.authorities"),
      requiredPermissions: {
        [EntityType.AUTHORITY]: ["READ"],
      },
      "component-id": "authoritiesPage",
    },
    {
      href: `/tenant/${tenant?.identifier.id}/users`,
      icon: Users,
      title: t("sidebar.users"),
      requiredPermissions: {
        [EntityType.USER]: ["READ"],
      },
      "component-id": "usersPage",
    },
    {
      href: `/tenant/${tenant?.identifier.id}/audit`,
      icon: Database,
      title: t("sidebar.audit"),
      "component-id": "tenantAuditPage",
    },
  ] as SidebarItemsType[];
};

const ProjectSection = ({ project, t }: ProjectSectionProps) => {
  return [
    {
      href: `/project/${project?.identifier.id}/overview`,
      icon: Home,
      title: t("sidebar.overview"),
      requiredPermissions: {
        [EntityType.PROJECT]: ["READ"],
      },
      "component-id": "projectOverviewPage",
    },
    {
      href: `/project/${project?.identifier.id}/dashboard`,
      icon: Layout,
      title: t("sidebar.dashboard"),
      requiredPermissions: {
        [EntityType.DASHBOARD]: ["READ"],
      },
      "component-id": "dashboardsPage",
    },
    {
      href: `/project/${project?.identifier.id}/device`,
      icon: Tablet,
      title: t("sidebar.devices"),
      requiredPermissions: {
        [EntityType.DEVICE]: ["READ"],
      },
      "component-id": "devicesPage",
    },
    {
      href: `/project/${project?.identifier.id}/assets`,
      icon: Codesandbox,
      title: t("sidebar.assets"),
      requiredPermissions: {
        [EntityType.ASSET]: ["READ"],
      },
      "component-id": "assetsPage",
    },
    {
      href: `/project/${project?.identifier.id}/specifications`,
      icon: Sliders,
      title: t("sidebar.specifications"),
      requiredPermissions: {
        [EntityType.DEVICE_SPECIFICATION]: ["READ"],
      },
      "component-id": "deviceSpecificationsPage",
    },
    {
      href: `/project/${project?.identifier.id}/alarms`,
      icon: Bell,
      title: t("sidebar.alarms"),
      requiredPermissions: {
        [EntityType.ALARM]: ["READ"],
      },
      "component-id": "alarmsPage",
    },
    {
      href: `/project/${project?.identifier.id}/integrations`,
      icon: Cloud,
      title: t("sidebar.integrations"),
      requiredPermissions: {
        [EntityType.INTEGRATION]: ["READ"],
      },
      "component-id": "integrationsPage",
    },
    {
      href: `/project/${project?.identifier.id}/workflows`,
      icon: Schema,
      title: t("sidebar.workflows"),
      requiredPermissions: {
        [EntityType.WORKFLOW]: ["READ"],
      },
      "component-id": "workflowsPage",
    },
    {
      href: `/project/${project?.identifier.id}/ota`,
      icon: DownloadCloud,
      title: t("sidebar.ota"),
      requiredPermissions: {
        [EntityType.OTA_PACKAGE]: ["READ"],
      },
      "component-id": "tenantsPage",
    },
    {
      href: `/project/${project?.identifier.id}/audit`,
      icon: Database,
      title: t("sidebar.audit"),
      requiredPermissions: {
        [EntityType.AUDIT]: ["READ"],
      },
      "component-id": "projectAuditPage",
    },
    {
      href: `/project/${project?.identifier.id}/templates`,
      icon: FileText,
      title: t("sidebar.templates"),
      "component-id": "templatesSection",
      children: [
        {
          href: `/project/${project?.identifier.id}/templates/deviceImport`,
          title: t("sidebar.deviceImport"),
          "component-id": "templatesSection.deviceImportPage",
        },
      ],
    },
  ] as SidebarItemsType[];
};

const navItems = ({ tenant, project, t }: NavItemsProps) => {
  const items: NavItem[] = [];

  const isSystemSectionVisible = SystemSection({ t }).some((item) =>
    resourcePermissionCheckVisibility(item["component-id"]),
  );

  if (isSystemSectionVisible)
    items.push({
      title: t("sidebar.settings"),
      pages: SystemSection({ t }),
    });

  const isTenantSectionVisible = TenantSection({ t, tenant }).some((item) =>
    resourcePermissionCheckVisibility(item["component-id"]),
  );

  if (tenant && isTenantSectionVisible) {
    items.push({
      title: tenant.name,
      pages: TenantSection({ t, tenant }),
      type: "tenant",
    });
  }

  if (project) {
    items.push({
      title: t("sidebar.projects"),
      pages: ProjectSection({ t, project }),
      type: "project",
    });
  }
  return items;
};

export default navItems;
