import { createSvgIcon } from "@mui/material";

const AcknowledgeAlarmIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 20C14 21.1 13.1 22 12 22C10.9 22 10 21.1 10 20H14ZM18 13V16L20 18V19H11.6825L13.8701 17H16V15L18 13ZM14.5 7.3836C14.8951 7.72054 15.2186 8.14091 15.4627 8.62178L16.2727 7.87834L17 7.21081C16.7215 6.75675 16.3872 6.3466 16 5.99085C15.3231 5.36891 14.4845 4.91326 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V12L8 13.6875V11C8 8.52 9.51 6.5 12 6.5C13.0044 6.5 13.8493 6.82866 14.5 7.3836ZM17.3906 7.95752C17.3995 7.97779 17.4084 7.99811 17.4171 8.0185L17.5 8.22087C17.4653 8.13196 17.4288 8.04416 17.3906 7.95752ZM4.5 17.5L4 18V19H6.5L4.5 17.5Z"
    />
    <path
      d="M5.42558 13.1095L5.29212 12.9891L5.15822 13.109L3.86658 14.2656L3.70078 14.4141L3.86606 14.5631L8.95077 19.1485L9.08471 19.2693L9.21865 19.1485L20.1339 9.30513L20.2986 9.1566L20.1339 9.00807L18.8514 7.85147L18.7175 7.73074L18.5836 7.85141L9.08477 16.4093L5.42558 13.1095Z"
      strokeWidth="0.4"
    />
  </svg>,
  "AcknowledgeAlarmIcon",
);

export default AcknowledgeAlarmIcon;
