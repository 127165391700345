import { AxiosError } from "axios";

const parseAxiosErrorMessage = (err: AxiosError): string => {
  const { code, message, config, request, response } = err;

  const parsedError = {
    code,
    message,
    config: {
      baseUrl: config?.baseURL,
      data: config?.data,
    },
    request: {
      status: request?.status,
      responseUrl: request?.responseURL,
      response: request?.response,
    },
    response: {
      status: response?.status,
      data: response?.data,
      config: {
        method: response?.config?.method,
        url: response?.config?.url,
        adapter: response?.config?.adapter,
        headers: response?.config?.headers,
      },
    },
  };

  return JSON.stringify(parsedError);
};

export default parseAxiosErrorMessage;
