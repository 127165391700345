import {
  DashboardPayloadPost,
  DashboardPayloadPut,
  DashboardResponse,
  DashboardResponseSearch,
} from "domain/entities/dashboard";

import {
  ImportResponse,
  ExportRequestBody,
  ResultType,
  SearchType,
} from "globalTypes";

import axiosClient from "./axios";
import {
  createApiQuery,
  createApiQueryInfinite,
  createSearchApiQuery,
} from "./queryUtils";

export const dashboardApi = {
  createDashboard: (data: DashboardPayloadPost) =>
    axiosClient.post<DashboardResponse>("/hive/dashboard", data),
  updateDashboardById: (dashboardId: string, data: DashboardPayloadPut) =>
    axiosClient.put<DashboardResponse>(`/hive/dashboard/${dashboardId}`, data),
  deleteDashboardById: (dashboardId: string) =>
    axiosClient.delete<DashboardResponse>(`/hive/dashboard/${dashboardId}`),
  importDashboards: (requestBody: DashboardResponse) =>
    axiosClient.post<ImportResponse>("/hive/dashboard/import", {
      entities: requestBody,
    }),
  exportDashboards: (requestBody: ExportRequestBody) =>
    axiosClient.post<DashboardResponseSearch>(
      "/hive/dashboard/export",
      requestBody,
    ),
};

export interface DashboardSearchQueryParams {
  name?: string;
  projectId?: string;
  overview?: boolean;
  searchType?: SearchType;
  resultType?: ResultType;
  entitiesPerPage?: number;
  page?: number;
}

export const useDashboardSearch = createSearchApiQuery<
  DashboardResponseSearch,
  undefined,
  DashboardSearchQueryParams
>("/hive/dashboard");

export const useDashboardInfiniteSearch = createApiQueryInfinite<
  DashboardResponseSearch,
  undefined,
  DashboardSearchQueryParams,
  number
>("/hive/dashboard", {
  getNextPageParam: (lastPage: { page: number; numberOfPages: number }) => {
    if (lastPage.page < lastPage.numberOfPages) {
      return lastPage.page + 1;
    }
  },
});

export const useDashboardById = createApiQuery<
  DashboardResponse,
  { dashboardId: string }
>("/hive/dashboard/{dashboardId}");
