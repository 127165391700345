import { TenantOverviewResponse } from "domain/entities/overview";
import {
  TenantPayloadPost,
  TenantResponse,
  TenantResponseSearch,
  ValidateTenantNameResponse,
} from "domain/entities/tenant";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export const tenantApi = {
  createTenant: (data: TenantPayloadPost) =>
    axiosClient.post<TenantResponse>("/hive/tenant", data),
  updateTenantById: (tenantId: string, data: TenantPayloadPost) =>
    axiosClient.put<TenantResponse>(`/hive/tenant/${tenantId}`, data),
  deleteTenantById: (tenantId: string) =>
    axiosClient.delete<TenantResponse>(`/hive/tenant/${tenantId}`),
  getTenantById: (tenantId: string) =>
    axiosClient.get<TenantResponse>(`/hive/tenant/${tenantId}`),
  validateTenantName: (name: string) =>
    axiosClient.post<ValidateTenantNameResponse>(`/hive/tenant/validate`, {
      value: name,
    }),
};

export interface TenantSearchQueryParams {
  name?: string;
  templateId?: string;
}

export const useTenantSearch = createSearchApiQuery<
  TenantResponseSearch,
  undefined,
  TenantSearchQueryParams
>("/hive/tenant");

export const useTenantById = createApiQuery<
  TenantResponse,
  { tenantId: string }
>("/hive/tenant/{tenantId}");

export const useTenantOverviewById = createApiQuery<
  TenantOverviewResponse,
  { tenantId: string }
>("/hive/tenant/overview/{tenantId}");
