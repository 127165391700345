import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import axios from "axios";
import {
  SnackbarOrigin,
  SnackbarProviderProps,
  useSnackbar as useNotificationStackSnackbar,
  enqueueSnackbar as enqueueNotificationStackSnackbar,
  VariantType,
} from "notistack";

import { SNACKBAR_HIDEOUT_DURATION, SNACKBAR_POSITION } from "utils/constants";
import parseAxiosErrorMessage from "utils/parseAxiosErrorMessage";

import { SnackbarMessageType } from "globalTypes";

const useSnackbar = () => {
  const { t } = useTranslation();
  const notificationStackSnackbar = useNotificationStackSnackbar();
  const enqueueSnackbar = useCallback(
    (
      message:
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | null,
      options?: SnackbarProviderProps,
    ) => {
      const defaultOptions = {
        variant: SnackbarMessageType.SUCCESS as VariantType,
        autoHideDuration: SNACKBAR_HIDEOUT_DURATION,
        anchorOrigin: SNACKBAR_POSITION as SnackbarOrigin,
        ...options,
      };
      notificationStackSnackbar.enqueueSnackbar(message, defaultOptions);
    },
    [notificationStackSnackbar],
  );

  const enqueueMutationErrorSnackbar = (
    err: unknown,
    options?: SnackbarProviderProps,
  ) => {
    let message = t("common.genericError");

    if (axios.isAxiosError(err)) {
      enqueueNotificationStackSnackbar(err.message, {
        variant: "expandableSnackbar",
        expandedMessage: err.response?.data?.error ?? null,
        fileText: parseAxiosErrorMessage(err),
        type: SnackbarMessageType.ERROR,
        persist: true,
        ...options,
      });
      return;
    } else if (typeof err === "string") {
      message = err;
    } else if (err instanceof Error) {
      message = err.message;
    }

    enqueueSnackbar(message, {
      variant: SnackbarMessageType.ERROR,
      ...options,
    });
  };

  return {
    ...notificationStackSnackbar,
    enqueueSnackbar,
    enqueueMutationErrorSnackbar,
  };
};

export default useSnackbar;
