import { Paper, Box } from "@mui/material";

import styled from "@emotion/styled";

export const TabPanelContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
`;

export const DetailedViewPaper = styled(Paper)`
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const DetailedViewContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

export const TitleAndImageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 1rem;
  align-items: center;
`;

export const PageTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;

export const DetailsContainer = styled(Box)`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
`;

export const DetailContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 0.75rem;
  border-radius: 8px;
`;
