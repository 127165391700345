export function createAPIQueryString(
  params: Record<string, string | string[]>,
): URLSearchParams {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value == null) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((val) => {
        searchParams.append(key, val);
      });
    } else {
      searchParams.set(key, value);
    }
  });

  return searchParams;
}
