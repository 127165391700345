import { EntityType } from "globalTypes";

export const alarmNavigationEntityTypes = {
  [EntityType.DEVICE]: EntityType.DEVICE,
  [EntityType.DEVICE_SPECIFICATION]: EntityType.DEVICE_SPECIFICATION,
  [EntityType.INTEGRATION]: EntityType.INTEGRATION,
  [EntityType.ASSET]: EntityType.ASSET,
};

export const alarmEntityLocations = {
  [alarmNavigationEntityTypes.DEVICE]: "device",
  [alarmNavigationEntityTypes.INTEGRATION]: "integrations",
  [alarmNavigationEntityTypes.DEVICE_SPECIFICATION]: "specifications",
  [alarmNavigationEntityTypes.ASSET]: "assets",
};
