import { AvatarProps } from "@mui/material";

import { NotificationPayloadGet } from "domain/entities/notification";

import { NotificationLevel } from "globalTypes";

export enum AlertItemType {
  ALARM = "alarm",
  NOTIFICATION = "notification",
}

export interface ColoredAvatarProps extends AvatarProps {
  status: string;
  seen: string;
}

type NotificationColours = "error" | "warning" | "info";

export const avatarColours: Record<NotificationLevel, NotificationColours> = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export type NotificationProps = {
  notification: NotificationPayloadGet;
  expandMessage: () => void;
  toggleSeen: () => void;
  maxMessageLength?: number;
  isNotificationOpen?: boolean;
};
