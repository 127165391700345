import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { Link, Typography } from "@mui/material";

import useTheme from "hooks/useTheme";

import { BREADCRUMB_FONT_SIZE } from "utils/constants";

import { RouteBreadcrumbProps } from "./types";

const themesForWhiteBreadcrumbColor = ["DARK", "LIGHT"];
export default function RouteBreadcrumb<TData>(
  props: RouteBreadcrumbProps<TData>,
) {
  let queryConfig: UseQueryOptions<TData, unknown, TData> = {
    enabled: false,
  };
  let label: string;
  let href: string | undefined;
  if ("dataQuery" in props) {
    queryConfig = props.dataQuery;
  }
  const { theme } = useTheme();
  const breadcrumbColor = themesForWhiteBreadcrumbColor.includes(theme)
    ? "white"
    : "black";

  const query = useQuery(queryConfig);
  const navigate = useNavigate();

  if (typeof props.label === "string") {
    label = props.label;
  } else {
    label = props.label(query.data);
  }

  if (typeof props.href === "string") {
    href = props.href;
  } else if (props.href != null) {
    href = props.href(query.data);
  }
  if (href) {
    return (
      <Link
        color={breadcrumbColor}
        underline="none"
        href={href}
        style={{
          display: "flex",
          columnGap: "0.25rem",
        }}
        onClick={(e) => {
          e.preventDefault();
          navigate(href!);
        }}
      >
        {props?.icon}
        {label}
      </Link>
    );
  } else {
    return (
      <Typography
        color={breadcrumbColor}
        style={{
          fontSize: BREADCRUMB_FONT_SIZE,
          fontWeight: "bold",
          display: "flex",
          columnGap: "0.25rem",
        }}
      >
        {props?.icon}
        {label}
      </Typography>
    );
  }
}
