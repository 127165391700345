import { forwardRef, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import { default as FileDownloadIcon } from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IconButton as MuiIconButton,
  Card as MuiCard,
  CardActions as MuiCardActions,
  Paper,
  Typography,
  Collapse,
  Button,
} from "@mui/material";

import styled from "@emotion/styled";
import { CustomContentProps, SnackbarContent } from "notistack";

import useSnackbar from "hooks/useSnackbar";
import { useSnackbarVariant } from "hooks/useSnackbarVariant";

import FileUtils from "utils/fileUtils";

import { SnackbarMessageType } from "globalTypes";

export interface ExpandableProps {
  expandedMessage?: string;
  fileText?: string;
  type: SnackbarMessageType;
}
export interface ExpandedSnackbarProps
  extends CustomContentProps,
    ExpandableProps {}

const ExpandableSnackbar = forwardRef<HTMLDivElement, ExpandedSnackbarProps>(
  ({ id, style, ...props }, ref) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const variant = useSnackbarVariant(props.type);

    const { t } = useTranslation("translation", {
      keyPrefix: "component.snackbar",
    });

    const { closeSnackbar } = useSnackbar();

    const handleExpandClick = useCallback(() => {
      setIsExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    const handleFileDownload = useCallback(() => {
      if (!props.fileText) return;

      FileUtils.downloadStringContent(
        props.fileText,
        "application/json",
        "hive_error_log.json",
      );
    }, [props.fileText]);

    const VariantIcon = variant.icon;

    return (
      <RootSnackbarContent ref={ref} style={style}>
        <Card sx={{ backgroundColor: variant.backgroundColor }}>
          <CardActions>
            <CardContent>
              <VariantIcon
                sx={{
                  color: variant.color,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: variant.color,
                }}
              >
                {props.message}
              </Typography>
            </CardContent>
            <IconGroup>
              <IconButton
                aria-label={t("expand")}
                size="small"
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon
                  sx={{
                    color: variant.color,
                  }}
                  className={isExpanded ? "expandOpen" : "expand"}
                />
              </IconButton>
              <IconButton size="small" onClick={handleDismiss}>
                <CloseIcon fontSize="small" sx={{ color: variant.color }} />
              </IconButton>
            </IconGroup>
          </CardActions>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <PaperWrapper sx={{ padding: "8px" }}>
              <PaperInner>
                <Typography
                  gutterBottom
                  variant="body2"
                  style={{
                    color: variant.backgroundColor,
                  }}
                >
                  {props.expandedMessage}
                </Typography>
                <Button
                  onClick={handleFileDownload}
                  disabled={!props.fileText}
                  startIcon={<FileDownloadIcon />}
                >
                  {t("downloadFile")}
                </Button>
              </PaperInner>
            </PaperWrapper>
          </Collapse>
        </Card>
      </RootSnackbarContent>
    );
  },
);

const RootSnackbarContent = styled(SnackbarContent)`
  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 370px;
  }
`;

const Card = styled(MuiCard)`
  width: 100%;
`;

const CardActions = styled(MuiCardActions)`
  padding: 8px 8px 8px 16px;
  justify-content: space-between;
`;

const CardContent = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(3)};
`;

const IconGroup = styled.div`
  margin-left: auto;
  min-width: 70px;
`;

const IconButton = styled(MuiIconButton)`
  .expand {
    transform: rotate(0deg);
    transition: all 0.2s;
  }

  .expandOpen {
    transform: rotate(90deg);
  }
`;

const PaperWrapper = styled(Paper)`
  background-color: ${(props) => props.theme.palette.background.default};
`;

const PaperInner = styled(Paper)`
  padding: 8px 16px;
  position: relative;
  max-height: 150px;
  max-width: 370px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: ${(props) => props.theme.palette.background.paper};
`;

ExpandableSnackbar.displayName = "ExpandableSnackbar";

export default ExpandableSnackbar;
