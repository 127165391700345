import {
  Bell,
  Book,
  Cloud,
  Codesandbox,
  Database,
  DownloadCloud,
  Layout,
  Monitor,
  Sliders,
  Tablet,
} from "react-feather";
import { createBrowserRouter, Params } from "react-router-dom";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts

import {
  Settings,
  People,
  MenuBook,
  Person,
  Smartphone,
  AppSettingsAlt,
  CloudCircle,
  WebAsset,
  PermIdentity,
  Schema,
  InstallMobile,
  OfflineShare,
} from "@mui/icons-material";

import { TFunction } from "i18next";

import ProtectedRoute from "components/protectedRoute/ProtectedRoute";
import { resourcePermissionCheckVisibility } from "components/resourceGuard/resourceGuardUtil";

import async from "./components/Async";
import { RouteBreadcrumbProps } from "./components/routeBreadcrumbs/types";
import {
  SkeletonDetails,
  SkeletonOverview,
  SkeletonTable,
} from "./components/skeletons";

// Error pages
import { Root } from "layouts/Root";

import Page404 from "pages/error/Page404";
import Page500 from "pages/error/Page500";

import { useAssetById } from "fetch/asset";
import { useAuthorityById } from "fetch/authority";
import { useDashboardById } from "fetch/dashboard";
import { useDeviceById } from "fetch/device";
import { useDeviceImportTemplateById } from "fetch/deviceImportTemplate";
import { useDeviceSpecificationById } from "fetch/deviceSpecification";
import { useIntegrationById } from "fetch/integration";
import { useOtaById } from "fetch/ota";
import { useProjectById } from "fetch/project";
import { useTenantById } from "fetch/tenant";
import { useUserById } from "fetch/user";
import { useUserProfileById } from "fetch/userProfile";

import { AuthorityResponse } from "domain/entities/authority";
import { DashboardResponse } from "domain/entities/dashboard";
import { ProjectResponse } from "domain/entities/project";
import { TenantResponse } from "domain/entities/tenant";
import { UserResponse } from "domain/entities/user";

// Pages
const AlarmsPage = async(
  () => import("./pages/alarms/AlarmsPage"),
  <SkeletonTable />,
);
const ProjectsPage = async(
  () => import("pages/projects/ProjectsPage"),
  <SkeletonTable />,
);
const TenantsPage = async(
  () => import("pages/tenants/TenantsPage"),
  <SkeletonTable />,
);
const TenantPage = async(() => import("pages/tenants"), <SkeletonDetails />);
const Overview = async(() => import("./pages/overview"), <SkeletonOverview />);
const ProjectPage = async(
  () => import("components/project"),
  <SkeletonDetails />,
);

const SettingsPage = async(() => import("./pages/settings"), <SkeletonTable />);
const OtasPage = async(() => import("pages/ota/OtaPage"), <SkeletonTable />);
const OtaPage = async(() => import("components/ota/Ota"), <SkeletonDetails />);

const DeviceSpecificationPage = async(
  () => import("./pages/deviceSpecification/DeviceSpecificationPage"),
  <SkeletonTable />,
);
const DeviceSpecification = async(
  () => import("components/deviceSpecification"),
  <SkeletonDetails />,
);
const DashboardsPage = async(
  () => import("./pages/dashboard/DashboardPage"),
  <SkeletonTable />,
);
const DashboardPage = async(() => import("components/dashboard"));
const AuditPage = async(
  () => import("./pages/audit/AuditPage"),
  <SkeletonTable />,
);
const DevicesPage = async(
  () => import("./pages/device/DevicePage"),
  <SkeletonTable />,
);
const DevicePage = async(
  () => import("components/device"),
  <SkeletonDetails />,
);
const AssetsPage = async(
  () => import("./pages/assets/AssetsPage"),
  <SkeletonTable />,
);
const AssetDetailPage = async(
  () => import("./pages/assets/AssetDetailPage"),
  <SkeletonDetails />,
);
const UsersPage = async(
  () => import("./pages/users/UsersPage"),
  <SkeletonTable />,
);
const UserPage = async(() => import("components/user"), <SkeletonDetails />);
const IntegrationsPage = async(
  () => import("./pages/integration/IntegrationPage"),
  <SkeletonTable />,
);
const IntegrationPage = async(
  () => import("components/integration"),
  <SkeletonDetails />,
);
const AuthoritiesPage = async(
  () => import("./pages/authority/AuthoritiesPage"),
  <SkeletonTable />,
);
const AuthorityPage = async(
  () => import("./pages/authority/AuthorityDetailPage"),
  <SkeletonDetails />,
);
const WorkflowsPage = async(
  () => import("./pages/workflow/WorkflowsPage"),
  <SkeletonTable />,
);
const WorkflowEditorPage = async(
  () => import("./pages/workflow/WorkflowEditorPage"),
  <SkeletonDetails />,
);
const DeviceImportTemplatesPage = async(
  () => import("./pages/templates/DeviceImportTemplates"),
  <SkeletonTable />,
);
const DeviceImportTemplatePage = async(
  () => import("components/deviceImportTemplate/DeviceImportTemplate"),
  <SkeletonDetails />,
);
const TenantSelectPage = async(
  () => import("pages/tenantSelector/TenantSelector"),
  <SkeletonDetails />,
);
const UserProfilePage = async(
  () => import("pages/userProfile/UserProfilePage"),
  <SkeletonDetails />,
);

export type CrumbFunction = <TData>(
  t: TFunction,
  params: Params,
) => RouteBreadcrumbProps<TData>;

export interface RouteHandle {
  crumb?: CrumbFunction;
}

const getRouter = () =>
  createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <Page500 />,
      children: [
        {
          path: "profile",
          element: <UserProfilePage />,
          errorElement: <Page500 />,
          handle: {
            crumb: (t, params) => ({
              dataQuery: {
                queryKey: useUserProfileById.getKey({
                  pathParams: {
                    userId: params.id!,
                  },
                }),
                queryFn: useUserProfileById.queryFn,
                queryKeyHashFn: useUserProfileById.queryKeyHashFn,
              },
              label: (data?: UserResponse) =>
                data?.payload.name.firstName.concat(
                  " ",
                  data?.payload.name.lastName,
                ) ?? t("page.users.detailedView.title"),
              icon: <Person />,
            }),
          } as RouteHandle,
          children: [
            {
              path: "*",
              element: <Page404 />,
            },
          ],
        },
        {
          path: `system`,
          children: [
            {
              path: "tenants",
              handle: {
                crumb: (t) => ({
                  label: t("page.tenants.title"),
                  icon: <People />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="tenantsPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <TenantsPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="tenantsPage.tenantsTable.clickTenantDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <TenantPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useTenantById.getKey({
                          pathParams: {
                            tenantId: params.id!,
                          },
                        }),
                        queryFn: useTenantById.queryFn,
                        queryKeyHashFn: useTenantById.queryKeyHashFn,
                      },
                      label: (data?: TenantResponse) =>
                        data?.payload.name ??
                        t("page.tenants.detailedView.title"),
                      icon: <Person />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "settings",
              element: (
                <ProtectedRoute
                  component-id="settingsPage"
                  resourceCheckFn={resourcePermissionCheckVisibility}
                >
                  <SettingsPage />
                </ProtectedRoute>
              ),
              errorElement: <Page500 />,
              handle: {
                crumb: (t) => ({
                  label: t("page.settingsPage.title"),
                  icon: <Settings />,
                }),
              } as RouteHandle,
            },
          ],
        },
        {
          path: `tenant/:activeTenantId`,
          children: [
            {
              path: "overview",
              element: (
                <ProtectedRoute
                  component-id="tenantOverviewPage"
                  resourceCheckFn={resourcePermissionCheckVisibility}
                >
                  <Overview type={"tenant"} />
                </ProtectedRoute>
              ),
              errorElement: <Page500 />,
              handle: {
                crumb: (t) => ({
                  label: t("page.tenantOverview.title"),
                  icon: <Book />,
                }),
              } as RouteHandle,
            },
            {
              path: "project",
              handle: {
                crumb: (t) => ({
                  label: t("page.project.title"),
                  icon: <Book />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="projectsPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <ProjectsPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="projectsPage.clickProjectDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <ProjectPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useProjectById.getKey({
                          pathParams: {
                            projectId: params.id!,
                          },
                        }),
                        queryFn: useProjectById.queryFn,
                        queryKeyHashFn: useProjectById.queryKeyHashFn,
                      },
                      label: (data?: ProjectResponse) =>
                        data?.payload.name ??
                        t("page.project.detailedView.title"),
                      icon: <MenuBook />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "authorities",
              handle: {
                crumb: (t) => ({
                  label: t("page.authorities.title"),
                  icon: <PermIdentity />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="authoritiesPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <AuthoritiesPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="authoritiesPage.authoritiesTable.clickAuthoritiesDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <AuthorityPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useAuthorityById.getKey({
                          pathParams: {
                            authorityId: params.id!,
                          },
                        }),
                        queryFn: useAuthorityById.queryFn,
                        queryKeyHashFn: useAuthorityById.queryKeyHashFn,
                      },
                      label: (data?: AuthorityResponse) =>
                        data?.payload.name ??
                        t("page.authorities.detailedView.title"),
                      icon: <Person />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "users",
              handle: {
                crumb: (t) => ({
                  label: t("page.users.title"),
                  icon: <People />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="usersPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <UsersPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="usersPage.usersTable.clickUserDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <UserPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useUserById.getKey({
                          pathParams: {
                            userId: params.id!,
                          },
                        }),
                        queryFn: useUserById.queryFn,
                        queryKeyHashFn: useUserById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name.firstName.concat(
                          " ",
                          data?.payload.name.lastName,
                        ) ?? t("page.users.detailedView.title"),
                      icon: <Person />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "audit",
              element: (
                <ProtectedRoute
                  component-id="tenantAuditPage"
                  resourceCheckFn={resourcePermissionCheckVisibility}
                >
                  <AuditPage type="tenant" />
                </ProtectedRoute>
              ),
              errorElement: <Page500 />,
              handle: {
                crumb: (t) => ({
                  label: t("page.audit.title.tenant"),
                  icon: <Database />,
                }),
              } as RouteHandle,
            },
          ],
        },
        {
          path: `project/:activeProjectId`,
          children: [
            {
              path: "overview",
              // DOCS - This is home route and we are ignoring permission for this one
              element: <Overview type={"project"} />,
              errorElement: <Page500 />,
            },
            {
              path: "dashboard",
              handle: {
                crumb: (t) => ({
                  label: t("page.dashboard.title"),
                  icon: <Layout />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="dashboardsPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <DashboardsPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="dashboardsPage.dashboardsTable.clickDashboardDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <DashboardPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useDashboardById.getKey({
                          pathParams: {
                            dashboardId: params.id!,
                          },
                        }),
                        queryFn: useDashboardById.queryFn,
                        queryKeyHashFn: useDashboardById.queryKeyHashFn,
                      },
                      label: (data?: DashboardResponse) =>
                        data?.payload.name ?? t("component.dashboard.title"),
                      icon: <Monitor />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "device",
              handle: {
                crumb: (t) => ({
                  label: t("page.device.title"),
                  icon: <Tablet />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="devicesPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <DevicesPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="devicesPage.devicesTable.clickDeviceDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <DevicePage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useDeviceById.getKey({
                          pathParams: {
                            deviceId: params.id!,
                          },
                        }),
                        queryFn: useDeviceById.queryFn,
                        queryKeyHashFn: useDeviceById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.device.detailedView.title"),
                      icon: <Smartphone />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "assets",
              handle: {
                crumb: (t) => ({
                  label: t("page.assets.title"),
                  icon: <Codesandbox />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="assetsPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <AssetsPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="assetsPage.assetsTable.clickAssetDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <AssetDetailPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useAssetById.getKey({
                          pathParams: {
                            assetId: params.id!,
                          },
                        }),
                        queryFn: useAssetById.queryFn,
                        queryKeyHashFn: useAssetById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.asset.detailedView.title"),
                      icon: <WebAsset />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "specifications",
              handle: {
                crumb: (t) => ({
                  label: t("page.deviceSpecification.title"),
                  icon: <Sliders />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="deviceSpecificationsPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <DeviceSpecificationPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="deviceSpecificationsPage.deviceSpecificationTable.clickDeviceSpecificationDetailedView"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <DeviceSpecification />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useDeviceSpecificationById.getKey({
                          pathParams: {
                            specificationId: params.id!,
                          },
                        }),
                        queryFn: useDeviceSpecificationById.queryFn,
                        queryKeyHashFn:
                          useDeviceSpecificationById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.deviceSpecification.detailedView.title"),
                      icon: <AppSettingsAlt />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "alarms",
              element: (
                <ProtectedRoute
                  component-id="alarmsPage"
                  resourceCheckFn={resourcePermissionCheckVisibility}
                >
                  <AlarmsPage />
                </ProtectedRoute>
              ),
              errorElement: <Page500 />,
              handle: {
                crumb: (t) => ({
                  label: t("page.alarms.title"),
                  icon: <Bell />,
                }),
              } as RouteHandle,
            },
            {
              path: "integrations",
              handle: {
                crumb: (t) => ({
                  label: t("page.integration.title"),
                  icon: <Cloud />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="integrationsPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <IntegrationsPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="integrationsPage.integrationsTable.clickIntegrationDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <IntegrationPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useIntegrationById.getKey({
                          pathParams: {
                            integrationId: params.id!,
                          },
                        }),
                        queryFn: useIntegrationById.queryFn,
                        queryKeyHashFn: useIntegrationById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.integration.detailedView.title"),
                      icon: <CloudCircle />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "workflows",
              handle: {
                crumb: (t) => ({
                  label: t("page.workflow.title"),
                  icon: <Schema />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="workflowsPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <WorkflowsPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="workflowsPage.workflowsTable.clickWorkflowDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <WorkflowEditorPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
              ],
            },
            {
              path: "ota",
              handle: {
                crumb: (t) => ({
                  label: t("page.ota.title"),
                  icon: <DownloadCloud />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute
                      component-id="otasPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <OtasPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      component-id="otasPage.otasTable.clickOtaDetailedPage"
                      resourceCheckFn={resourcePermissionCheckVisibility}
                    >
                      <OtaPage />
                    </ProtectedRoute>
                  ),
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useOtaById.getKey({
                          pathParams: {
                            otaId: params.id!,
                          },
                        }),
                        queryFn: useOtaById.queryFn,
                        queryKeyHashFn: useOtaById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ?? t("page.ota.detailedView.title"),
                      icon: <Cloud />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "audit",
              element: (
                <ProtectedRoute
                  component-id="projectAuditPage"
                  resourceCheckFn={resourcePermissionCheckVisibility}
                >
                  <AuditPage type="project" />
                </ProtectedRoute>
              ),
              errorElement: <Page500 />,
              handle: {
                crumb: (t) => ({
                  label: t("page.audit.title.project"),
                  icon: <Database />,
                }),
              } as RouteHandle,
            },
            {
              path: "templates",
              children: [
                {
                  path: "deviceImport",
                  handle: {
                    crumb: (t: TFunction) => ({
                      label: t("page.templates.title"),
                      icon: <OfflineShare />,
                    }),
                  },
                  children: [
                    {
                      index: true,
                      element: (
                        <ProtectedRoute
                          component-id="templatesSection.deviceImportPage"
                          resourceCheckFn={resourcePermissionCheckVisibility}
                        >
                          <DeviceImportTemplatesPage />
                        </ProtectedRoute>
                      ),
                      errorElement: <Page500 />,
                    },
                    {
                      path: ":id",
                      element: (
                        <ProtectedRoute
                          component-id="templatesSection.deviceImportPage.deviceImportTable.clickDeviceImportDetailedPage"
                          resourceCheckFn={resourcePermissionCheckVisibility}
                        >
                          <DeviceImportTemplatePage />
                        </ProtectedRoute>
                      ),
                      errorElement: <Page500 />,
                      handle: {
                        crumb: (t, params) => ({
                          dataQuery: {
                            queryKey: useDeviceImportTemplateById.getKey({
                              pathParams: {
                                deviceImportTemplateId: params.id!,
                              },
                            }),
                            queryFn: useDeviceImportTemplateById.queryFn,
                            queryKeyHashFn:
                              useDeviceImportTemplateById.queryKeyHashFn,
                          },
                          label: (data?: UserResponse) =>
                            data?.payload.name ??
                            t("page.templates.detailedView.title"),
                          icon: <InstallMobile />,
                        }),
                      } as RouteHandle,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
    {
      path: "/tenantSelect",
      element: <TenantSelectPage />,
      errorElement: <Page500 />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
  ]);

export default getRouter;
