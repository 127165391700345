import React, { useState } from "react";

import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { Palette as PaletteIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Drawer,
  Grid,
  ListItemButton,
  Typography,
  Tooltip,
  IconButton as MuiIconButton,
} from "@mui/material";
import { green, grey, indigo } from "@mui/material/colors";

import styled from "@emotion/styled";

import useTheme from "hooks/useTheme";

import { THEMES } from "../../constants";

type ButtonType = {
  active?: boolean;
};

type ButtonInnerType = {
  selectedTheme: string;
};

type Types = {
  title: string;
  themeVariant: string;
};

function ThemeItem({ title, themeVariant }: Readonly<Types>) {
  const { theme, setTheme } = useTheme();

  return (
    <Grid item xs={6}>
      <Button
        active={themeVariant === theme}
        onClick={() => setTheme(themeVariant)}
      >
        <ButtonInner selectedTheme={themeVariant} />
      </Button>
      <Title variant="subtitle2" gutterBottom>
        {title}
      </Title>
    </Grid>
  );
}

function ThemeList() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Heading>{t("common.theme.title")} </Heading>

      <Box px={4} my={3}>
        <Alert icon={false} severity="info">
          <div>
            <p dangerouslySetInnerHTML={{ __html: t("common.theme.text") }}></p>
          </div>
        </Alert>
      </Box>

      <Box px={4} my={3}>
        <Grid container spacing={3}>
          <ThemeItem
            title={t("common.theme.items.dark")}
            themeVariant={THEMES.DARK}
          />
          <ThemeItem
            title={t("common.theme.items.light")}
            themeVariant={THEMES.LIGHT}
          />
          <ThemeItem
            title={t("common.theme.items.default")}
            themeVariant={THEMES.DEFAULT}
          />
          <ThemeItem
            title={t("common.theme.items.blue")}
            themeVariant={THEMES.BLUE}
          />
          <ThemeItem
            title={t("common.theme.items.green")}
            themeVariant={THEMES.GREEN}
          />
          <ThemeItem
            title={t("common.theme.items.indigo")}
            themeVariant={THEMES.INDIGO}
          />
        </Grid>
      </Box>
    </Wrapper>
  );
}

function NavbarTheme() {
  const { t } = useTranslation();

  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open: boolean) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>
      <Tooltip title={t("navbar.theme.tooltip")}>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          size="large"
          onClick={toggleDrawer(true)}
          style={{ zIndex: 1000 }}
        >
          <PaletteIcon />
        </IconButton>
      </Tooltip>

      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <ThemeList />
      </Drawer>
    </React.Fragment>
  );
}

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Button = styled.div<ButtonType>`
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  height: 80px;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  position: relative;
  border: 1px solid
    ${(props) =>
      !props.active
        ? props.theme.palette.action.selected
        : props.theme.palette.action.active};
`;

const ButtonInner = styled.div<ButtonInnerType>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(props) => props.theme.palette.action.selected};
  position: relative;

  ${(props) =>
    props.selectedTheme === THEMES.DEFAULT &&
    css`
      background: linear-gradient(-45deg, #23303f 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.DARK &&
    css`
      background: #23303f;
    `}
  ${(props) =>
    props.selectedTheme === THEMES.LIGHT &&
    css`
      background: ${grey[100]};
    `}
  ${(props) =>
    props.selectedTheme === THEMES.BLUE &&
    css`
      background: linear-gradient(-45deg, #4782da 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.GREEN &&
    css`
      background: linear-gradient(-45deg, ${green[500]} 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.INDIGO &&
    css`
      background: linear-gradient(-45deg, ${indigo[500]} 50%, ${grey[100]} 0);
    `}
`;

const Title = styled(Typography)`
  text-align: center;
`;

const Wrapper = styled.div`
  width: 258px;
  overflow-x: hidden;
`;

const Heading = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

export default NavbarTheme;
