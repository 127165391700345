import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import styled from "@emotion/styled";

import useLanguages from "hooks/useLanguages";
import useUserPreferencesStore from "hooks/useUserPreferences";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

type LanguageProps = {
  icon: string;
  name: string;
};

type LanguageOptionsType = {
  [key: string]: LanguageProps;
};

type NavbarLanguagesDropdownProps = {
  languages?: string[];
};

export const languageOptions: LanguageOptionsType = {
  "en-GB": {
    icon: "/static/img/flags/gb.png",
    name: "English",
  },
  "hr-HR": {
    icon: "/static/img/flags/hr.png",
    name: "Hrvatski",
  },
  "sr-Cyrl": {
    icon: "/static/img/flags/rs.png",
    name: "Српски",
  },
};

function NavbarLanguagesDropdown({
  languages,
}: Readonly<NavbarLanguagesDropdownProps>) {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageProps>(
    languageOptions["en-GB"],
  );
  const [anchorMenu, setAnchorMenu] = useState<any>(null);
  const { language, setLanguage } = useUserPreferencesStore();
  const { filteredLanguages } = useLanguages({ languages });

  const handleLanguageChange = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
      setSelectedLanguage(languageOptions[locale]);
      setLanguage(locale);
      closeMenu();
    },
    [i18n, setLanguage],
  );

  useEffect(() => {
    const lang = language || i18n.language || "en-GB";

    setSelectedLanguage(languageOptions[lang] || languageOptions["en-GB"]);
  }, [i18n.language, language]);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <>
      <Tooltip title={t("navbar.languages.tooltip")}>
        <IconButton
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
        </IconButton>
      </Tooltip>

      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {filteredLanguages.map((language) => (
          <MenuItem
            key={language.name}
            onClick={() => handleLanguageChange(language.code)}
          >
            {languageOptions[language.code].name ?? language.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default NavbarLanguagesDropdown;
