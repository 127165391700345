import { PropsWithChildren, ReactNode } from "react";

import { CacheProvider, EmotionCache } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { Store } from "redux";

import ExpandableSnackbar from "components/expandableSnackbar";

import { SNACKBAR_MAXIMUM_STACK } from "./utils/constants";

import { ThemeContext, ThemeProvider } from "./contexts/ThemeContext";
import createTheme from "./theme";

import "./i18n";

export default function Providers({
  children,
  queryClient,
  reduxStore,
  emotionCache,
}: Readonly<{
  queryClient: QueryClient;
  reduxStore: Store;
  children: ReactNode;
  emotionCache: EmotionCache;
}>) {
  return (
    <ThemeProvider>
      <ThemeContext.Consumer>
        {({ theme }) => (
          <QueryClientProvider client={queryClient}>
            <Provider store={reduxStore}>
              <CacheProvider value={emotionCache}>
                <HelmetProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MuiThemeProvider theme={createTheme(theme)}>
                      <SnackbarProvider
                        maxSnack={SNACKBAR_MAXIMUM_STACK}
                        Components={{
                          expandableSnackbar: ExpandableSnackbar,
                        }}
                      >
                        <ConfirmProviderCustom>
                          {children}
                        </ConfirmProviderCustom>
                      </SnackbarProvider>
                    </MuiThemeProvider>
                  </LocalizationProvider>
                </HelmetProvider>
              </CacheProvider>
            </Provider>
          </QueryClientProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeProvider>
  );
}

const ConfirmProviderCustom = (props: PropsWithChildren) => {
  const { t } = useTranslation();
  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: { autoFocus: true },
        dialogProps: {
          sx: {
            ".MuiDialog-paper": {
              padding: "20px 24px 16px",
              gap: "1rem",
            },
          },
        },
        titleProps: { sx: { padding: "0" } },
        contentProps: { sx: { padding: "0" } },
        dialogActionsProps: { sx: { padding: "0" } },
        cancellationText: t("common.cancel"),
      }}
    >
      {props.children}
    </ConfirmProvider>
  );
};
