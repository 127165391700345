import React, { ReactNode } from "react";

import { useLocation } from "react-router-dom";

import { resourcePermissionCheck } from "components/resourceGuard/resourceGuardUtil";

import { SidebarItemsType } from "types/sidebar";

import reduceChildRoutes from "./reduceChildRoutes";

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        resourcePermision: resourcePermissionCheck,
      }),
    [] as ReactNode[],
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
