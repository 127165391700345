import { Box } from "@mui/material";

import styled from "@emotion/styled";

export const RoundedBox = styled(Box)({
  borderRadius: "0.25rem",
  color: "#fff",
  maxWidth: "9ch",
  padding: "0.25rem",
});

export const ActionBox = styled(Box)`
  display: flex;
  justify-content: end;
`;
