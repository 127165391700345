import { useMemo } from "react";

import useDashboardStateParam, {
  DashboardStateParam,
  DashboardState,
} from "./useDashboardStateParam";

export const useGetLastDashboardView = (): {
  dashboardState: DashboardStateParam;
  lastDashboardView: DashboardState | undefined;
} => {
  const dashboardStateParam = useDashboardStateParam();
  const lastDashboardView = useMemo(
    () => dashboardStateParam.getLastView(),
    [dashboardStateParam],
  );
  return { dashboardState: dashboardStateParam, lastDashboardView };
};
