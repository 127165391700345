import { TelemetryEntityType } from "fetch/telemetry";

import { NotificationPayloadGet } from "domain/entities/notification";
import { TelemetryLatestPayload } from "domain/entities/telemetry";
import {
  UsageGroupTypes,
  UsageProtocols,
  UsageTypes,
} from "domain/entities/usage";

import {
  AggregationFunction,
  Alarm,
  AlarmSeverity,
  AlarmStatusType,
  EntityId,
  TelemetryGroupBy,
  TimeRange,
} from "globalTypes";

export enum WSRequestType {
  TELEMETRY = "SUBSCRIBE_TELEMETRY",
  VIDEO = "SUBSCRIBE_VIDEO",
  ALARM = "SUBSCRIBE_ALARM",
  USAGE = "SUBSCRIBE_USAGE",
  CLEARED_ALARM = "CLEARED_ALARM",
  NOTIFICATION = "SUBSCRIBE_NOTIFICATION",
  SEEN_NOTIFICATION = "SEEN_NOTIFICATION",
  UNSUBSCRIBE = "UNSUBSCRIBE",
}

export enum WSHealthcheckType {
  PING = "ping",
  PONG = "pong",
}

export enum WSCommandScope {
  LATEST_TELEMETRY = "LATEST_TELEMETRY",
  VIDEO = "VIDEO",
  NOTIFICATION = "NOTIFICATION",
  UNSUBSCRIBE = "UNSUBSCRIBE",
  LATEST_USAGE = "LATEST_USAGE",
  ALARM = "ALARM",
}

export interface BaseWSSubscriptionRequest {
  tenantId: string;
  user: string;
  type: WSRequestType;
  widgetId: string;
  scope?: WSCommandScope;
}

interface QueryFields {
  timeframe: number;
  interval: number;
  limit: number;
  aggregation: AggregationFunction;
  groupBy?: TelemetryGroupBy;
}

export interface TelemetryWSSubscriptionRequest
  extends BaseWSSubscriptionRequest,
    QueryFields {
  entities: string[];
  fields?: string[];
  entityType?: TelemetryEntityType;
}

export interface UsageDataWSSubscriptionRequest
  extends BaseWSSubscriptionRequest,
    Omit<QueryFields, "groupBy"> {
  projects: string[];
  types?: UsageTypes[];
  protocols?: UsageProtocols[];
  groupBy: UsageGroupTypes;
}

export interface VideoWSSubscriptionRequest extends BaseWSSubscriptionRequest {
  deviceId: string;
  scope: WSCommandScope.VIDEO;
}

export interface AlarmWSSubscriptionRequest extends BaseWSSubscriptionRequest {
  entities: EntityId[];
  alarmType?: string;
  severities?: AlarmSeverity[];
  statuses?: AlarmStatusType[];
  startTimeFilter?: TimeRange;
  endTimeFilter?: TimeRange;
  acknowledgedTimeFilter?: TimeRange;
  clearedTimeFilter?: TimeRange;
  projects: string[];
}

export type UnsubscribeWSRequest = BaseWSSubscriptionRequest;

interface WSError {
  code: number;
  message: string;
}

interface WSCode {
  name: string;
  code: number;
  category: string;
  description: string;
}

interface BaseWSResponse {
  widgetId: string;
  sessionId: string;
  error: WSError;
  code: WSCode;
}

export interface TelemetryWSResponsePayload extends BaseWSResponse {
  telemetries: TelemetryLatestPayload[];
}

export interface VideoWSResponsePayload extends BaseWSResponse {
  data: ArrayBuffer;
}

export interface AlarmWSResponsePayload extends BaseWSResponse {
  alarms: Alarm[];
}

export interface NotificationWSResponsePayload extends BaseWSResponse {
  notifications: NotificationPayloadGet[];
}
