import { AxiosProgressEvent } from "axios";

import {
  OtaPayloadPost,
  OtaPayloadPut,
  OtaResponse,
  OtaResponseSearch,
  OtaType,
  OtaUploadPayload,
} from "domain/entities/ota";

import axiosClient, { objectToFormData } from "./axios";
import {
  createApiQuery,
  createSearchApiQuery,
  useApiMutation,
} from "./queryUtils";

export const otaApi = {
  createOta: (data: OtaPayloadPost) =>
    axiosClient.post<OtaResponse>("/hive/ota", data),
  updateOtaById: (otaId: string, data: OtaPayloadPut) =>
    axiosClient.put<OtaResponse>(`/hive/ota/${otaId}`, data),
  deleteOtaById: (otaId: string) =>
    axiosClient.delete<OtaResponse>(`/hive/ota/${otaId}`),
  uploadOtaFile: (
    otaId: string,
    data: OtaUploadPayload,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) => {
    const { file, ...rest } = data;
    const formData = objectToFormData({ file });
    return axiosClient.put<OtaResponse>(`/hive/ota/upload/${otaId}`, formData, {
      params: rest,
      onUploadProgress,
    });
  },
  deleteOtaFile: (otaId: string) =>
    axiosClient.put<OtaResponse>(`/hive/ota/remove/${otaId}`),
};

export interface OtaSearchQueryParams {
  projectId: string;
  type?: OtaType;
  name?: string;
  version?: string;
  fileName?: string;
}

export const useOtaSearch = createSearchApiQuery<
  OtaResponseSearch,
  undefined,
  OtaSearchQueryParams
>("/hive/ota");

export const useOtaById = createApiQuery<OtaResponse, { otaId: string }>(
  "/hive/ota/{otaId}",
  60 * 1000,
);

export const useOtaMutationUpdate = (id: string | undefined) => {
  return useApiMutation(
    (payload: OtaPayloadPut) => {
      return otaApi.updateOtaById(id ?? "", payload);
    },
    (response) => {
      return response.data?.responseDetail ?? "OK";
    },
    {
      meta: {
        dataKeys: [useOtaById.getPrimaryKey(), { pathParams: { otaId: id } }],
      },
    },
  );
};
