import axios from "axios";

import { createAPIQueryString } from "utils/createAPIQueryString";

import {
  handleAuthRequestError,
  handleRequest,
  handleResponseError,
} from "./utils";

export const axiosAuthClient = axios.create({
  baseURL: window._env_.REACT_APP_AUTH_HOST,
});

axiosAuthClient.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

axiosAuthClient.interceptors.response.use(
  (response) => response,
  (error) => handleAuthRequestError(error),
);

const axiosApiClient = axios.create({
  baseURL: window._env_.REACT_APP_API_HOST,
  paramsSerializer: (params) => {
    return createAPIQueryString(params).toString();
  },
});

axiosApiClient.interceptors.request.use(
  (config) => handleRequest(config),
  (error) => Promise.reject(error),
);

axiosApiClient.interceptors.response.use(
  (response) => response,
  (error) => handleResponseError(error),
);

export default axiosApiClient;
