import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

const SkeletonNotification = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 4 }}>
      <Skeleton variant="circular" width={40} height={40} />
      <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" />
      </Box>
    </Box>
  );
};

export default SkeletonNotification;
