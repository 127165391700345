import React, { ReactNode, createContext, useMemo } from "react";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { THEMES } from "../constants";

const initialState = {
  theme: THEMES.DEFAULT,
  // DOCS - this is needed because even though theme is not used in the initial state, it is used in the context value
  // eslint-disable-next-line
  setTheme: (theme: string) => {},
};
const ThemeContext = createContext(initialState);

type ThemeProviderProps = {
  children: ReactNode;
};

function ThemeProvider({ children }: Readonly<ThemeProviderProps>) {
  const theme = useUserPreferencesStore((state) => state.theme);
  const setTheme = useUserPreferencesStore((state) => state.setTheme);

  const contextValue = useMemo(() => ({ theme, setTheme }), [theme, setTheme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
