import { useCallback, useEffect, useState } from "react";

import useWebSocket from "react-use-websocket";

import isEmpty from "lodash.isempty";
import omitBy from "lodash.omitby";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";

import { WS_CONFIG, createWsUri } from "fetch/websocket/websocket";

import { NotificationPayloadGet } from "domain/entities/notification";

import { getAccessToken } from "utils/jwt";

import { WSCommandScope, WSRequestType } from "types/websocket";

import useCurrentRefValue from "./useCurrentRefValue";
import useUserPreferencesStore from "./useUserPreferences";

export const useNotificationWebsocket = (widgetId?: string, seen?: boolean) => {
  const [data, setData] = useState<NotificationPayloadGet[] | null | undefined>(
    null,
  );

  const [isLoading, setIsLoading] = useState(false);

  const tenantId = useUserPreferencesStore(
    (state) => state.tenant?.identifier.id,
  );

  const userId = useUserPreferencesStore((state) => state.user?.identifier.id);

  const projectId = useUserPreferencesStore(
    (state) => state.project?.identifier.id,
  );

  const handleMessage = useCallback((message: MessageEvent<string>) => {
    const newNotification: NotificationPayloadGet[] = JSON.parse(
      message.data,
    )?.notifications;

    setData(newNotification);
    setIsLoading(false);
  }, []);

  const wsClient: WebSocketHook = useWebSocket(createWsUri(), {
    ...WS_CONFIG,
    onMessage: handleMessage,
    protocols: [getAccessToken() ?? ""],
    share: true,
  });

  const subscribe = useCallback(
    (
      tenantId: string,
      userId: string,
      projectId: string,
      widgetId?: string,
      seen?: boolean,
    ) => {
      const wsObject = {
        tenantId: tenantId,
        user: userId,
        projects: [projectId],
        scope: WSCommandScope.NOTIFICATION,
        type: WSRequestType.NOTIFICATION,
        widgetId: widgetId ?? "",
        unseen: seen ?? true,
      };
      wsClient.sendJsonMessage(omitBy(wsObject, isEmpty));
      setIsLoading(true);
    },
    [wsClient],
  );

  const toggleNotificationSeen = useCallback(
    (notificationId: string) => {
      wsClient.sendJsonMessage({
        tenantId: tenantId,
        user: userId,
        type: WSRequestType.SEEN_NOTIFICATION,
        notificationId: notificationId,
      });
    },
    [tenantId, userId, wsClient],
  );

  useEffect(() => {
    if (wsClient.readyState !== 1 || !tenantId || !userId || !projectId) return;
    subscribe(tenantId, userId, projectId, widgetId, seen);
    // eslint-disable-next-line
  }, [wsClient.readyState]);

  const stale = useCurrentRefValue(data);

  return {
    data: data,
    isLoading: isLoading,
    toggleNotificationSeen: toggleNotificationSeen,
    stale: stale,
  };
};
