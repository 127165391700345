import { default as ErrorIcon } from "@mui/icons-material/Cancel";
import { default as SuccessIcon } from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material/styles";

import { SnackbarMessageType } from "globalTypes";

const iconMap = {
  [SnackbarMessageType.ERROR]: ErrorIcon,
  [SnackbarMessageType.SUCCESS]: SuccessIcon,
  [SnackbarMessageType.WARNING]: WarningIcon,
  [SnackbarMessageType.INFO]: InfoIcon,
};

export const useSnackbarVariant = (type?: SnackbarMessageType) => {
  const theme = useTheme();

  return {
    backgroundColor: theme.palette[type ?? SnackbarMessageType.INFO].dark,
    color: "white",
    icon: iconMap[type ?? SnackbarMessageType.INFO],
  };
};
