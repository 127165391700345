import { ControllerRenderProps } from "react-hook-form";

import { AggregationFunction } from "globalTypes";

export enum TimeframeType {
  HISTORIC = "HISTORICAL",
  REALTIME = "REALTIME",
}

export enum RealTimeType {
  PREDEFINED = "PREDEFINED",
  LATEST = "LATEST",
}

export enum PreviousTimeFrameValue {
  CURRENT_HOUR = "CURRENT_HOUR",
  CURRENT_DAY = "CURRENT_DAY",
  CURRENT_WEEK = "CURRENT_WEEK",
  CURRENT_MONTH = "CURRENT_MONTH",
  YESTERDAY = "YESTERDAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
}

export interface TimeframeBase {
  interval?: number;
  limit?: number;
  aggregation: AggregationFunction;
  dataPoints: number;
  type: string;
  historicPredefined: PredefinedHistoricTimeFrame;
}

export interface TimeframeHistorical extends TimeframeBase {
  previous?: PreviousTimeFrameValue;
  timeframeShift?: number;
  last?: number;
  from: string;
  to: string;
}

export interface TimeframeRealtime extends TimeframeBase {
  realTimeType: RealTimeType;
  period: number;
}

export enum PredefinedHistoricTimeFrame {
  LAST = "LAST",
  PREDEFINED = "PREDEFINED",
  CUSTOM = "CUSTOM",
}

export interface TimeFrameDialogProps {
  anchor: HTMLElement | null;
  handleClose: () => void;
}

export interface TimeFrameFormProps {
  type: TimeframeType;
}

export interface CustomControlledInputProps {
  label?: string;
  field: ControllerRenderProps<any, any>;
}
