import { Avatar as MuiAvatar } from "@mui/material";

import styled from "@emotion/styled";

import { AlarmSeverity, NotificationLevel } from "globalTypes";

import { ColoredAvatarProps, StatusColor } from "./types";

export const notificationStatusColours: Record<NotificationLevel, StatusColor> =
  {
    ERROR: {
      color: "error",
      paletteColor: "main",
    },
    WARNING: {
      color: "warning",
      paletteColor: "main",
    },
    INFO: {
      color: "info",
      paletteColor: "main",
    },
  };

export const alarmSeverityColours: Record<AlarmSeverity, StatusColor> = {
  CRITICAL: {
    color: "error",
    paletteColor: "dark",
  },
  MAJOR: {
    color: "warning",
    paletteColor: "dark",
  },
  MINOR: {
    color: "info",
    paletteColor: "dark",
  },
  WARNING: {
    color: "warning",
    paletteColor: "main",
  },
  UNKNOWN: {
    color: "text",
    paletteColor: "main",
  },
};

const Avatar = styled(MuiAvatar)<ColoredAvatarProps>(
  ({ theme, status, seen }) => ({
    backgroundColor: theme.palette[status.color][status.paletteColor],
    color: theme.palette[status.color].contrastText,
    opacity: seen ? 0.5 : 1,
  }),
);

export default Avatar;
