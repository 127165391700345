import "vendor/perfect-scrollbar.css";

import { Skeleton } from "@mui/material";

import { DetailedViewPaper } from "shared/styledComponents/DetailedView";

const SkeletonDetails: React.FC = () => {
  return (
    <Skeleton variant="rounded">
      <DetailedViewPaper />
    </Skeleton>
  );
};

export default SkeletonDetails;
