import { Options } from "react-use-websocket";

import { UsageDatasource } from "components/dashboard/widgets/usage/settings/types";
import { TimeframeRealtime } from "components/timeframe/types";
import { GenericDatasourceSchemaType } from "components/widgets/configuration/types";

import { UsageGroupTypes } from "domain/entities/usage";

import { AggregationFunction, TelemetryGroupBy, WidgetType } from "globalTypes";
import {
  TelemetryWSSubscriptionRequest,
  UsageDataWSSubscriptionRequest,
  WSCommandScope,
  WSHealthcheckType,
  WSRequestType,
} from "types/websocket";

const handleWsMessage = (e: any) => {
  if (e.data === WSHealthcheckType.PING) {
    e.target.sendMessage(WSHealthcheckType.PONG);
  }
};

export const WS_CONFIG: Options = {
  retryOnError: true,
  reconnectAttempts: 3,
  share: true,
  shouldReconnect: () => true,
  onMessage: handleWsMessage,
};

export const createWsPayloadFromDataSource = (
  dataSource: GenericDatasourceSchemaType,
  widgetType: WidgetType,
  tenantId: string,
  userId: string,
  widgetId: string,
  groupBy: TelemetryGroupBy = TelemetryGroupBy.FIELD,
  entities: string[] = [],
): TelemetryWSSubscriptionRequest => {
  const [scope, requestType] =
    widgetType === WidgetType.VIDEO
      ? [WSCommandScope.VIDEO, WSRequestType.VIDEO]
      : [WSCommandScope.LATEST_TELEMETRY, WSRequestType.TELEMETRY];

  return {
    tenantId: tenantId,
    user: userId,
    type: requestType,
    widgetId: widgetId,
    entityType: dataSource.entityType,
    scope: scope,
    entities,
    fields: dataSource.fields?.map((field) => field.name),
    timeframe: (dataSource.telemetry?.timeframe as TimeframeRealtime)?.period,
    interval: dataSource.telemetry?.timeframe?.interval as number,
    limit: dataSource.telemetry?.timeframe?.limit as number,
    aggregation: dataSource.telemetry?.timeframe
      ?.aggregation as AggregationFunction,
    groupBy: groupBy ?? TelemetryGroupBy.FIELD,
  };
};

export const createWsPayloadFromUsageDataSource = (
  dataSource: UsageDatasource,
  widgetId: string,
  userId: string,
  tenantId: string,
): UsageDataWSSubscriptionRequest => {
  return {
    tenantId: tenantId,
    user: userId,
    type: WSRequestType.USAGE,
    widgetId: widgetId,
    scope: WSCommandScope.LATEST_USAGE,
    projects: dataSource.projects.map((project) => project.id),
    types:
      dataSource.usageTypes && dataSource.usageTypes.length > 0
        ? dataSource.usageTypes
        : undefined,
    protocols:
      dataSource.usageProtocols && dataSource.usageProtocols.length > 0
        ? dataSource.usageProtocols
        : undefined,
    timeframe: (dataSource.telemetry?.timeframe as TimeframeRealtime)?.period,
    interval: dataSource.telemetry?.timeframe?.interval as number,
    limit: dataSource.telemetry?.timeframe?.limit as number,
    aggregation: dataSource.telemetry?.timeframe
      ?.aggregation as AggregationFunction,
    groupBy: dataSource.groupBy ?? UsageGroupTypes.TYPE,
  };
};

export function createWsUri(): string {
  const BASE = window._env_.REACT_APP_WS_HOST;
  const PORT = window._env_.REACT_APP_WS_PORT;
  const SUFFIX = "/hive/duplex";

  if (!BASE) {
    throw new Error("Cannot use WebSocket due to no URL defined.");
  }

  return PORT ? BASE + ":" + PORT + SUFFIX : BASE + SUFFIX;
}
