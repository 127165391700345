import { useCallback, useEffect, useState } from "react";

import useWebSocket from "react-use-websocket";

import isEmpty from "lodash.isempty";
import omitBy from "lodash.omitby";
import uniq from "lodash.uniq";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";

import { AlarmDatasource } from "components/dashboard/widgets/alarm/settings/types";

import { WS_CONFIG, createWsUri } from "fetch/websocket/websocket";

import { AlarmPayloadGet } from "domain/entities/alarm";

import { getAccessToken } from "utils/jwt";

import { WSRequestType } from "types/websocket";

import useCurrentRefValue from "./useCurrentRefValue";
import useUserPreferencesStore from "./useUserPreferences";

export const useAlarmWebsocket = (
  widgetId?: string,
  seen?: boolean,
  datasource?: AlarmDatasource,
) => {
  const [data, setData] = useState<AlarmPayloadGet[] | null | undefined>(null);

  const [isLoading, setIsLoading] = useState(false);

  const tenantId = useUserPreferencesStore(
    (state) => state.tenant?.identifier.id,
  );

  const userId = useUserPreferencesStore((state) => state.user?.identifier.id);

  const projectId = useUserPreferencesStore(
    (state) => state.project?.identifier.id,
  );

  const handleMessage = useCallback((message: MessageEvent<string>) => {
    const newAlarm: AlarmPayloadGet[] = JSON.parse(message.data)?.alarms;

    setData(newAlarm);
    setIsLoading(false);
  }, []);

  const wsClient: WebSocketHook = useWebSocket(createWsUri(), {
    ...WS_CONFIG,
    onMessage: handleMessage,
    protocols: [getAccessToken() ?? ""],
    share: true,
  });

  const subscribe = useCallback(
    (
      tenantId: string,
      userId: string,
      projectId: string,
      widgetId?: string,
      datasource?: AlarmDatasource,
    ) => {
      const wsObject = {
        tenantId: tenantId,
        projects: datasource?.projects.map((project) => project.value) ?? [
          projectId,
        ],
        user: userId,
        type: WSRequestType.ALARM,
        widgetId: widgetId ?? "",
        entities:
          uniq(datasource?.entities.map((entity) => entity.value)) ?? [],
        alarmType: datasource?.type,
        severities: datasource?.severities,
        statuses: datasource?.statuses,
        startTimeFilter: datasource?.startTimeFilter,
        endTimeFilter: datasource?.endTimeFilter,
        acknowledgedTimeFilter: datasource?.acknowledgedTimeFilter,
        clearedTimeFilter: datasource?.clearedTimeFilter,
      };
      wsClient.sendJsonMessage(omitBy(wsObject, isEmpty));
      setIsLoading(true);
    },
    [wsClient],
  );

  const toggleAlarmCleared = useCallback(
    (alarmId: string) => {
      wsClient.sendJsonMessage({
        tenantId: tenantId,
        user: userId,
        type: WSRequestType.CLEARED_ALARM,
        alarmId: alarmId,
      });
    },
    [tenantId, userId, wsClient],
  );

  useEffect(() => {
    if (wsClient.readyState !== 1 || !tenantId || !userId || !projectId) return;
    subscribe(tenantId, userId, projectId, widgetId, datasource);
    // eslint-disable-next-line
  }, [wsClient.readyState]);

  const previous = useCurrentRefValue(data);

  return {
    data: data,
    isLoading: isLoading,
    toggleAlarmCleared,
    previous: previous,
  };
};
