import { CircularProgress } from "@mui/material";

import styled from "@emotion/styled";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

type LoaderProps = {
  size?: number;
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
};

const LoaderDefaultProps = {
  size: 40,
};

const Loader = ({ size, color = "primary" }: LoaderProps) => {
  return (
    <Root>
      <CircularProgress size={size} color={color} />
    </Root>
  );
};

Loader.defaultProps = LoaderDefaultProps;

export default Loader;
