import { CommandResponse } from "domain/entities/command";
import {
  NotificationResponseSearch,
  NotificationResponse,
} from "domain/entities/notification";

import { NotificationLevel } from "globalTypes";

import axiosClient from "./axios";
import {
  SearchParams,
  createApiQuery,
  createApiQueryInfinite,
  createSearchApiQuery,
} from "./queryUtils";

export interface SearchNotificationParams {
  projects: string;
  message?: string;
  levels?: NotificationLevel[];
  seenFrom?: string;
  seenTo?: string;
  createdFrom?: string;
  createdTo?: string;
  unseen: boolean;
}

export const notificationApi = {
  createMessage: (body: {
    projectId: string;
    message: string;
    level: string;
  }) => axiosClient.post<CommandResponse>("/hive/notification", body),
};

export const useNotificationSearch = createSearchApiQuery<
  NotificationResponseSearch,
  undefined,
  SearchNotificationParams
>("/hive/notification");

export const useNotificationSearchInfinite = createApiQueryInfinite<
  NotificationResponseSearch,
  undefined,
  SearchParams,
  number
>("/hive/notification", {
  getNextPageParam: (lastPage) => {
    if (lastPage.page < lastPage.numberOfPages) {
      return lastPage.page + 1;
    }
  },
});

export const useNotificationById = createApiQuery<
  NotificationResponse,
  { notificationId: string }
>("/hive/notification/{notificationId}");
