import { Grid as MuiGrid, Skeleton } from "@mui/material";

import styled from "@emotion/styled";

const SkeletonOverview: React.FC = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={6} lg={3}>
        <SkeletonCard variant="rounded" />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <SkeletonCard variant="rounded" />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <SkeletonCard variant="rounded" />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <SkeletonCard variant="rounded" />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SkeletonCard variant="rounded" />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SkeletonCard variant="rounded" />
      </Grid>
      <Grid item xs={12} lg={4}>
        <SkeletonCard variant="rounded" />
      </Grid>
      <Grid item xs={12} lg={8}>
        <ChartSkeleton variant="rounded" />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Skeleton variant="rounded" width="100%" height="100%" />
      </Grid>
    </Grid>
  );
};

const Grid = styled(MuiGrid)`
  padding-bottom: ${(props) => props.theme.spacing(6)};
`;

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 70px;
`;

const ChartSkeleton = styled(Skeleton)`
  width: 100%;
  height: 378px;
`;

export default SkeletonOverview;
