import { PropsWithChildren, useEffect } from "react";

import { AxiosError } from "axios";

import Page500 from "pages/error/Page500";

import useSnackbar from "hooks/useSnackbar";

import { RESPONSE_FORBIDDEN_STATUS } from "utils/constants";

import { SnackbarMessageType } from "globalTypes";

export function Content({
  error,
  children,
}: Readonly<
  PropsWithChildren<{
    error: AxiosError<unknown> | null;
  }>
>) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      error?.message &&
      error?.response?.status !== RESPONSE_FORBIDDEN_STATUS
    ) {
      enqueueSnackbar(error.message, {
        variant: SnackbarMessageType.ERROR,
      });
    }
  }, [error, enqueueSnackbar]);

  if (error?.response?.status === RESPONSE_FORBIDDEN_STATUS) return children;

  if (error) return <Page500 />;

  return children;
}

export default Content;
