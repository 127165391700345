import { PropsWithChildren } from "react";

import { Navigate } from "react-router-dom";

import { ProtectedRouteType } from "./types";

const ProtectedRoute = (props: PropsWithChildren<ProtectedRouteType>) => {
  const { resourceCheckFn, redirectRoute } = props;
  const hasPermission = resourceCheckFn(props["component-id"]);
  if (!hasPermission) {
    return <Navigate to={redirectRoute ?? "/"} replace />;
  }

  return props.children;
};

export default ProtectedRoute;
