import { languageOptions } from "components/navbar/NavbarLanguagesDropdown";

type UseLanguagesProps = {
  languages?: string[];
};

export default function useLanguages({ languages }: UseLanguagesProps) {
  const languageOptionsArray = Object.entries(languageOptions).map(
    ([code, { name }]) => ({ code, name }),
  );

  const filteredLanguages = languageOptionsArray.filter((option) =>
    languages?.includes(option.name),
  );

  return { filteredLanguages };
}
