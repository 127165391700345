import React from "react";

import {
  Box,
  List,
  Skeleton as MuiSkeleton,
  Drawer as MuiDrawer,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import styled from "@emotion/styled";

import "vendor/perfect-scrollbar.css";

import Loader from "components/Loader";

import { DRAWER_WIDTH } from "utils/constants";

const HamburgerLoader = styled.div`
  background: ${(props) => props.theme.header.background};
  position: absolute;
  top: ${(props) => props.theme.spacing(5)};
  left: ${(props) => props.theme.spacing(5)};
  z-index: 1200;
`;

const SkeletonSidebar: React.FC = () => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.down("lg"));

  if (isLgUp)
    return (
      <HamburgerLoader>
        <Loader size={25} color="secondary" />
      </HamburgerLoader>
    );
  return (
    <SidebarDrawer>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <SidebarDrawerInner variant="permanent">
          <Brand>
            <Skeleton variant="rounded" animation="wave">
              <BrandIcon />
            </Skeleton>
          </Brand>
          <div>
            <List>
              <Items>
                {Array.from(Array(5).keys()).map((key) => (
                  <Box key={key} width={DRAWER_WIDTH}>
                    <Item>
                      <Skeleton
                        variant="circular"
                        width={20}
                        height={20}
                        animation="wave"
                      />

                      <Skeleton variant="text" width="100%" animation="wave">
                        <Title></Title>
                      </Skeleton>
                    </Item>
                  </Box>
                ))}
              </Items>
            </List>
          </div>
        </SidebarDrawerInner>
      </Box>
    </SidebarDrawer>
  );
};

const SidebarDrawerInner = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;

    background-color: ${(props) => props.theme.sidebar.background};
  }
`;

const Skeleton = styled(MuiSkeleton)`
  background-color: ${(props) => props.theme.sidebar.color};
  opacity: 0.2;
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  flex-grow: 0;
  background-color: ${(props) => props.theme.sidebar.header.background};

  ${(props) => props.theme.breakpoints.up("sm")} {
    height: 64px;
  }
`;

const BrandIcon = styled.img`
  margin-right: ${(props) => props.theme.spacing(2)};
  width: 92px;
  height: 42px;
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const Item = styled.div`
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(8)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  gap: ${(props) => props.theme.spacing(2)};
`;

const SidebarDrawer = styled.div`
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: ${DRAWER_WIDTH}px;
    flex-shrink: 0;
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

export default SkeletonSidebar;
