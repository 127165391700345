import { useState, useEffect, useMemo, useCallback } from "react";

import { isEqual, debounce } from "lodash";

import { NotificationDataSource } from "components/dashboard/widgets/notification/settings/types";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { useNotificationSearchInfinite } from "fetch/notification";

import { ResultType, SearchType } from "globalTypes";

import { useNotificationWebsocket } from "./useNotificationWebsocket";

export const useNotifications = (
  widgetId?: string,
  dataSource?: NotificationDataSource,
) => {
  const [showSeen, setShowSeen] = useState(dataSource?.seen ?? false);
  const projectId = useUserPreferencesStore(
    (state) => state.project?.identifier.id,
  );

  const { data: notificationPages, ...query } = useNotificationSearchInfinite({
    variables: {
      queryParams: {
        projects: projectId,
        unseen: !showSeen ? "true" : "false",
        levels: dataSource?.levels.toString(),
        createdFrom: dataSource?.createdFrom,
        createdTo: dataSource?.createdTo,
        searchType: SearchType.STANDARD,
        resultType: ResultType.COMPLETE,
        entitiesPerPage: 10,
      },
    },
    enabled: !!projectId,
  });

  const {
    data: realTimeNotifications,
    stale: staleRealTimeNotifications,
    isLoading,
    toggleNotificationSeen,
  } = useNotificationWebsocket(widgetId, dataSource?.seen);

  const debouncedRefetch = useCallback(
    () =>
      debounce(() => {
        query.refetch();
      }, 300),
    [query],
  );

  useEffect(() => {
    if (
      !isLoading &&
      notificationPages &&
      realTimeNotifications &&
      !isEqual(realTimeNotifications, staleRealTimeNotifications)
    ) {
      debouncedRefetch();
    }
    // eslint-disable-next-line
  }, [
    staleRealTimeNotifications,
    isLoading,
    projectId,
    query,
    debouncedRefetch,
  ]);

  useEffect(() => {
    if (dataSource) {
      setShowSeen(dataSource.seen);
      query.refetch();
    }
    // eslint-disable-next-line
  }, [dataSource]);

  const notifications = useMemo(() => {
    if (!notificationPages) return [];
    return notificationPages.pages.flatMap((page) => page.payload);
  }, [notificationPages]);

  const newNotificationCount = useMemo(() => {
    if (!showSeen) {
      return notificationPages?.pages[0]?.numberOfRecords;
    }

    return notifications?.filter((notification) => !notification.seenAt).length;
  }, [notificationPages?.pages, notifications, showSeen]);

  return {
    showSeen,
    setShowSeen,
    notifications,
    toggleNotificationSeen,
    newNotificationCount,
    query,
  };
};
