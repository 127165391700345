import Joi from "joi";

import { AggregationFunction } from "globalTypes";

import {
  RealTimeType,
  TimeframeType,
  TimeframeHistorical,
  TimeframeRealtime,
  PredefinedHistoricTimeFrame,
} from "../types";

export const timeframeDefaultValues = {
  type: TimeframeType.HISTORIC,
  from: new Date().toISOString(),
  to: new Date().toISOString(),
  aggregation: AggregationFunction.NONE,
  limit: 100,
  realTimeType: RealTimeType.PREDEFINED,
  period: 86400,
  interval: 10,
  dataPoints: 0,
  historicPredefined: PredefinedHistoricTimeFrame.CUSTOM,
};

export const timeframeValidationSchema = Joi.object<
  TimeframeHistorical | TimeframeRealtime
>().keys({
  type: Joi.string().valid(...Object.values(TimeframeType)),
  realTimeType: Joi.string()
    .valid(...Object.values(RealTimeType))
    .when("type", {
      is: TimeframeType.REALTIME,
      then: Joi.required(),
      otherwise: Joi.any().strip(),
    }),
  period: Joi.number()
    .when("type", {
      is: TimeframeType.REALTIME,
      otherwise: Joi.any().strip(),
    })
    .concat(
      Joi.number().when("realTimeType", {
        is: RealTimeType.PREDEFINED,
        then: Joi.required(),
        otherwise: Joi.any().strip(),
      }),
    ),
  interval: Joi.when("type", {
    is: TimeframeType.REALTIME,
    then: Joi.when("realTimeType", {
      is: RealTimeType.PREDEFINED,
      then: Joi.number().max(Joi.ref("period")),
      otherwise: Joi.optional().allow(null),
    }),
    otherwise: Joi.optional().allow(null),
  }),
  limit: Joi.number(),
  aggregation: Joi.string()
    .required()
    .valid(...Object.values(AggregationFunction)),

  historicPredefined: Joi.string().valid(
    ...Object.values(PredefinedHistoricTimeFrame),
  ),
  previous: Joi.string().when("historicPredefined", {
    is: PredefinedHistoricTimeFrame.PREDEFINED,
    then: Joi.required(),
    otherwise: Joi.any().strip(),
  }),
  timeframeShift: Joi.number().when("historicPredefined", {
    is: PredefinedHistoricTimeFrame.PREDEFINED,
    then: Joi.required(),
    otherwise: Joi.any().strip(),
  }),
  last: Joi.number().when("historicPredefined", {
    is: PredefinedHistoricTimeFrame.LAST,
    then: Joi.required(),
    otherwise: Joi.any().strip(),
  }),
  from: Joi.string().when("type", {
    is: TimeframeType.HISTORIC,
    then: Joi.when("historicPredefined", {
      is: PredefinedHistoricTimeFrame.CUSTOM,
      then: Joi.required(),
      otherwise: Joi.any().strip(),
    }),
    otherwise: Joi.when("realTimeType", {
      is: RealTimeType.LATEST,
      then: Joi.required(),
      otherwise: Joi.any().strip(),
    }),
  }),
  to: Joi.string().when("type", {
    is: TimeframeType.HISTORIC,
    then: Joi.when("historicPredefined", {
      is: PredefinedHistoricTimeFrame.CUSTOM,
      then: Joi.required(),
      otherwise: Joi.any().strip(),
    }),
    otherwise: Joi.when("realTimeType", {
      is: RealTimeType.LATEST,
      then: Joi.required(),
      otherwise: Joi.any().strip(),
    }),
  }),
  dataPoints: Joi.number().empty(""),
});
