import {
  Skeleton,
  Typography,
  Stack as MuiStack,
  Box as MuiBox,
  Divider as MuiDivider,
} from "@mui/material";

import styled from "@emotion/styled";

const SkeletonTable: React.FC<{ width?: string }> = ({ width = "100%" }) => {
  return (
    <Stack>
      <Box>
        <Skeleton variant="text">
          <Typography variant="h3">placeholder</Typography>
        </Skeleton>
        <Skeleton variant="text">
          <Typography variant="subtitle1">
            a fake description and some letters
          </Typography>
        </Skeleton>
      </Box>
      <Divider />
      <Skeleton variant="rounded" width={width} height="500px" />
    </Stack>
  );
};

const Stack = styled(MuiStack)`
  min-height: 100%;
`;

const Box = styled(MuiBox)`
  display: flex;
  flex-direction: column;
`;

const Divider = styled(MuiDivider)`
  margin-top: ${(props) => props.theme.spacing(4)};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  border-color: "none";
  color: "none";
  background-color: "none";
  opacity: 0;
`;

export default SkeletonTable;
