import {
  AuthorityPayloadPost,
  AuthorityPayloadPut,
  AuthorityResponse,
  AuthorityResponseGet,
  AuthorityResponseSearch,
} from "domain/entities/authority";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export interface AuthoritySearchQueryParams {
  name?: string;
}

export const authorityApi = {
  createAuthority: (data: AuthorityPayloadPost) =>
    axiosClient.post<AuthorityResponse>("/hive/authority", data),
  updateAuthorityById: (id: string, data: AuthorityPayloadPut) =>
    axiosClient.put<AuthorityResponse>(`/hive/authority/${id}`, data),
  deleteAuthorityById: (id: string) =>
    axiosClient.delete<AuthorityResponse>(`/hive/authority/${id}`),
};

export const useAuthoritySearch = createSearchApiQuery<
  AuthorityResponseSearch,
  undefined,
  AuthoritySearchQueryParams | undefined
>("hive/authority");

export const useAuthorityById = createApiQuery<
  AuthorityResponse,
  { authorityId: string }
>("hive/authority/{authorityId}");

export const useGetTenantAuthorities = createApiQuery<AuthorityResponseGet>(
  "hive/authority/tenant",
);
